import { baseApi } from "../../../api/base-api";
import { OtherPrice } from "../../canvas/models";
import { UpdateOtherPriceDto } from "./dto";

type GetOtherPricesResponse = {
    laminate: OtherPrice;
    packaging: OtherPrice;
    minPackaging: OtherPrice;
}

async function getOtherPrices() {
    return await baseApi.get<GetOtherPricesResponse>('canvas/other-prices');
}

async function updateOtherPrice(updateData: UpdateOtherPriceDto) {
    return await baseApi.put(`canvas/other-prices`, updateData);
}

export const otherPricesApi = {
    getOtherPrices: getOtherPrices,
    updateOtherPrice: updateOtherPrice,
} as const;
