export class SinglePrintWrap {
    id!: number;
    name!: string;
    type!: SinglePrintWrapType;
    imageUrl?: string;
}

export enum SinglePrintWrapType {
    Gallery = "Gallery",
    Roll = "Roll",
}
