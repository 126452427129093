import { useState } from "react";
import { toast } from 'react-toastify';
import { calculateLaminatePrice, calculateOrderItemPrice, calculateOrderPrice, calculatePackagingPrice, calculateShippingPrice, useCalculateSubTotal } from "../../../utils/order-price.util";
import { DeliveryMethod, useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { LAMINATE_PRICE_PER_SQFT, PACKAGING_PRICE_PER_SQFT } from "../../../constants/data.constant";
import { useLoaderStore } from "../../../stores/loader.store";
import { useCanvasNavigationStore } from "../../../stores/navigation.store";
import { SinglePrintOrderItemDto } from "../dto/create-single-print-order.dto";
import { dataUriToBlob, getCroppedImageDataUrl } from "../../../utils/image.util";
import { generateS3ImageUploadKey, generateS3OrderDetailsFileUploadKey, uploadBlobToS3 } from "../../../utils/s3.util";
import { generateOrderDetailsText } from "../../../utils/order-details.util";
import { createSinglePrintOrder, createSinglePrintOrderForUser } from "../../../api/base-api";
import { LOCAL_STORAGE_KEYS } from "../../../constants/local-storage.constant";
import { SinglePrintWrapType } from "../models";
import { useAuthStore } from "../../../stores/auth.store";
import { ApiResponse } from "../../../api/api-response.model";

const QUANTITY_SELECT_DROPDOWN_LIMIT = 50;
const QUANTITY_SELECT_DROPDOWN_VALUES: number[] = [];

for (let i = 1; i <= QUANTITY_SELECT_DROPDOWN_LIMIT; i++) {
    QUANTITY_SELECT_DROPDOWN_VALUES.push(i);
}

export function OrderSummary() {
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const deliveryMethod = useCanvasOrderStore((state) => state.deliveryMethod);
    const setQuantity = useCanvasOrderStore((state) => state.setQuantity);
    const removeItem = useCanvasOrderStore((state) => state.removeItem);
    const setSelectedOrderItemIndex = useCanvasOrderStore((state) => state.setSelectedOrderItemIndex);
    const getShippingInfo = useCanvasOrderStore((state) => state.getShippingInfo);
    const subtotal = useCalculateSubTotal();
    const user = useAuthStore((state) => state.user);
    const shippingPrice = deliveryMethod === DeliveryMethod.SHIPPING ? calculateShippingPrice(orderItems) : 0;
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);
    const setLoadingText = useLoaderStore((state) => state.setLoadingText);
    const setCurrentTab = useCanvasNavigationStore((state) => state.setCurrentTab);

    const discountPercentage = user?.discountPercentage || 0;
    const { totalPrice, taxAmount, discountAmount } = calculateOrderPrice(subtotal, shippingPrice, discountPercentage);

    async function handleCheckout() {
        setIsCheckingOut(true);
        setIsLoading(true);

        try {
            if (!validateOrder()) {
                return;
            }

            if (deliveryMethod === DeliveryMethod.SHIPPING && !validateShippingInfo()) {
                return;
            }

            const orderItemsDto: SinglePrintOrderItemDto[] = [];

            for (let i = 0; i < orderItems.length; i++) {
                const orderItem = orderItems[i];

                setLoadingText(`Uploading cropped image #${i + 1}`);

                const croppedImageDataUrl = await getCroppedImageDataUrl(orderItem.imgSrc, orderItem.cropDetails);
                const blobData = dataUriToBlob(croppedImageDataUrl);

                const uploadKey = generateS3ImageUploadKey('jpeg', 'crop');
                const croppedImageUrl = await uploadCroppedImage(blobData, uploadKey);

                orderItemsDto.push({
                    originalFileUrl: orderItem.imgSrc,
                    croppedFileUrl: croppedImageUrl,
                    cropDetails: orderItem.cropDetails,
                    quantity: orderItem.quantity,
                    canvasDetails: {
                        sizeId: orderItem.canvasDetails.size?.id!,
                        wrapId: orderItem.canvasDetails.wrap?.id!,
                        borderId: orderItem.canvasDetails.border?.id,
                        borderColour: orderItem.canvasDetails.borderColour,
                        shouldLaminate: orderItem.canvasDetails.shouldLaminate,
                        hardwareItemId: orderItem.canvasDetails.hardwareItem?.id,
                        colourFinishingId: orderItem.canvasDetails.colourFinishing?.id,
                        photoRetouchings: orderItem.canvasDetails.photoRetouchings,
                        retouchingDescription: orderItem.canvasDetails.retouchingDescription,
                    },
                });
            }

            setLoadingText('Checking out');

            const croppedFileUrls = orderItemsDto.map((item) => item.croppedFileUrl);
            const orderDetailsFileUrl = await uploadOrderDetailsFile(croppedFileUrls);

            let res: ApiResponse;

            if (user) {
                res = await createSinglePrintOrderForUser({
                    orderItems: orderItemsDto,
                    orderDetailsFileUrl: orderDetailsFileUrl,
                    deliveryMethod: deliveryMethod,
                });
            } else {
                res = await createSinglePrintOrder({
                    orderItems: orderItemsDto,
                    orderDetailsFileUrl: orderDetailsFileUrl,
                    deliveryMethod: deliveryMethod,
                });
            }

            if (res.success) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.LATEST_ORDER_ID, res.data?.orderId);
                navigateToPaymentPage(res.data?.url);
                return;
            }

            toast.error('Error checking out');
        } catch (err) {
            console.log(err);
            toast.error('Error checking out');
        } finally {
            setIsCheckingOut(false);
            setIsLoading(false);
        }
    }

    function validateOrder(): boolean {
        for (let i = 0; i < orderItems.length; i++) {
            const orderItem = orderItems[i];

            if (!orderItem.imgSrc) {
                toast.warning(`Please select an image for item ${i + 1}`);
                setSelectedOrderItemIndex(i);
                setCurrentTab('image');
                return false;
            }

            if (!orderItem.canvasDetails.size) {
                toast.warning(`Please select a size for item ${i + 1}`);
                setSelectedOrderItemIndex(i);
                setCurrentTab('size');
                return false;
            }

            if (!orderItem.canvasDetails.wrap) {
                toast.warning(`Please select a wrap for item ${i + 1}`);
                setSelectedOrderItemIndex(i);
                setCurrentTab('wrap');
                return false;
            }

            if (!orderItem.canvasDetails.border && orderItem.canvasDetails.wrap?.type !== SinglePrintWrapType.Roll) {
                toast.warning(`Please select a border for item ${i + 1}`);
                setSelectedOrderItemIndex(i);
                setCurrentTab('border');
                return false;
            }
        }

        return true;
    }

    function validateShippingInfo(): boolean {
        const shippingInfo = getShippingInfo();

        if (!shippingInfo.firstName || !shippingInfo.lastName) {
            toast.error(`First name and last name is required`);
            return false;
        }

        if (!shippingInfo.address || !shippingInfo.city || !shippingInfo.country) {
            toast.error(`Address/City/Country/State is required`);
            return false;
        }

        if (!shippingInfo.state || !shippingInfo.postalCode) {
            toast.error(`State/Postal code is required`);
            return false;
        }

        if (!shippingInfo.phoneNumber) {
            toast.error(`Phone number is required`);
            return false;
        }

        return true;
    }

    async function uploadCroppedImage(imageBlobData: Blob, uploadPathKey: string): Promise<string> {
        try {
            const croppedImageUrl = await uploadBlobToS3(imageBlobData, uploadPathKey);

            return croppedImageUrl;
        } catch (err) {
            throw err;
        }
    }

    async function uploadOrderDetailsFile(croppedFileUrls: string[]) {
        try {
            const shippingInfo = getShippingInfo();
            const orderDetailsText = generateOrderDetailsText(orderItems, shippingInfo, croppedFileUrls, deliveryMethod);
            const orderDetailsFileUploadKey = generateS3OrderDetailsFileUploadKey();
            const orderDetailsTextBlob = new Blob([orderDetailsText], { type: 'text/plain' });
            const orderDetailsFileUrl = await uploadBlobToS3(orderDetailsTextBlob, orderDetailsFileUploadKey);

            return orderDetailsFileUrl;
        } catch (err) {
            throw err;
        }
    }

    function navigateToPaymentPage(url: string) {
        window.location.href = url;
    }

    return (
        <div className="mt-10 lg:mt-0">
            <h4 className="mb-2 text-xl font-semibold">Order Summary</h4>

            <div>
                <h3 className="sr-only">Items in your cart</h3>
                <ul role="list" className="divide-y divide-gray-200">
                    {orderItems?.map((orderItem, index) => (
                        <li key={index} className="flex py-6">
                            <div className="flex-shrink-0">
                                {orderItem.imgSrc ? (
                                    <img className="w-28 h-auto" src={orderItem.imgSrc} alt="" />
                                ) : (
                                    <div className="w-28 h-24 bg-gray-100"></div>
                                )}
                            </div>

                            <div className="ml-6 flex flex-1 flex-col">
                                <div className="flex">
                                    <div className="min-w-0 flex-1">
                                        <h4 className="text-sm">
                                            <a href="#" className="font-medium text-gray-700 hover:text-gray-800">Item Name</a>
                                        </h4>
                                        <p className="mt-1 text-xs text-gray-500">Size: {orderItem.canvasDetails.size?.width}"x{orderItem.canvasDetails.size?.height}"</p>
                                        <p className="mt-1 text-xs text-gray-500">Wrap: {orderItem.canvasDetails.wrap?.name}</p>
                                        <p className="mt-1 text-xs text-gray-500">Border: {orderItem.canvasDetails.border?.name}</p>
                                        <p className="mt-1 text-xs text-gray-500">Hardware: {orderItem.canvasDetails.hardwareItem?.name}</p>
                                        <p className="mt-1 text-xs text-gray-500">Colour Finishing: {orderItem.canvasDetails.colourFinishing?.name}</p>
                                        <p className="mt-1 text-xs text-gray-500">Retouchings: {orderItem.canvasDetails.photoRetouchings?.join(', ')}</p>
                                        <p className="mt-1 text-xs text-gray-500">Major Retouchings: {orderItem.canvasDetails.retouchingDescription}</p>
                                        <p className="mt-1 text-xs text-gray-500">
                                            Laminate: ${(orderItem.canvasDetails.shouldLaminate && orderItem.canvasDetails.size) ? calculateLaminatePrice(orderItem.canvasDetails.size, LAMINATE_PRICE_PER_SQFT) : 0}
                                        </p>
                                        <p className="mt-1 text-xs text-gray-500">
                                            Packaging & Handling Fee: ${orderItem.canvasDetails.size ? calculatePackagingPrice(orderItem.canvasDetails.size, PACKAGING_PRICE_PER_SQFT) : 0}
                                        </p>
                                    </div>

                                    <div className="ml-4 flow-root flex-shrink-0">
                                        <button
                                            type="button"
                                            title="Remove"
                                            className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                                            onClick={() => removeItem(index)}
                                        >
                                            <span className="sr-only">Remove</span>
                                            <svg className="w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-1 items-end justify-between pt-2">
                                    <p className="mt-1 text-sm font-medium text-gray-900">${calculateOrderItemPrice(orderItem).toFixed(2)}</p>

                                    <div className="ml-4">
                                        <label htmlFor="quantity" className="sr-only">Quantity</label>
                                        <select
                                            id="quantity"
                                            name="quantity"
                                            value={orderItem.quantity}
                                            onChange={(e) => setQuantity(parseInt(e.target.value), index)}
                                            className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            {QUANTITY_SELECT_DROPDOWN_VALUES.map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <dl className="py-6 space-y-6 border-t border-gray-200">
                    <div className="flex items-center justify-between">
                        <dt className="text-sm">Subtotal</dt>
                        <dd className="text-sm font-medium text-gray-900">${subtotal}</dd>
                    </div>
                    <div className="flex items-center justify-between">
                        <dt className="text-sm">Shipping</dt>
                        <dd className="text-sm font-medium text-gray-900">${shippingPrice}</dd>
                    </div>
                    <div className="flex items-center justify-between">
                        <dt className="text-sm">Taxes</dt>
                        <dd className="text-sm font-medium text-gray-900">${taxAmount}</dd>
                    </div>
                    <div className="flex items-center justify-between">
                        <dt className="text-sm">Discount</dt>
                        <dd className="text-sm font-medium text-gray-900">-${discountAmount}</dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                        <dt className="text-base font-medium">Total</dt>
                        <dd className="text-base font-medium text-gray-900">${totalPrice}</dd>
                    </div>
                </dl>

                <div className="py-6 border-t border-gray-200">
                    <button
                        className="w-full px-5 py-2.5 space-x-1 border border-blue-700 flex items-center justify-center text-sm font-medium rounded-md bg-blue-700 text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                        type="button"
                        disabled={isCheckingOut}
                        onClick={handleCheckout}
                    >
                        <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                        </svg>
                        <span className="leading-loose">Checkout</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
