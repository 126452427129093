import { Fragment, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Dialog, Transition } from '@headlessui/react';
import {
    Bars3Icon,
    PhotoIcon,
    XMarkIcon,
    ScissorsIcon,
    UserGroupIcon,
    UserCircleIcon,
    Square2StackIcon,
    Square3Stack3DIcon,
    CurrencyDollarIcon,
    ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline';
import { classNames } from '../../../utils/class-names.util';
import { LOCAL_STORAGE_KEYS } from '../../../constants/local-storage.constant';
import { useAuthStore } from '../../../stores/auth.store';
import { baseApiInterceptors } from '../../../api/base-api.interceptor';

const navigationItems = [
    {
        name: 'Premium Artist Members',
        to: 'premium-artist-members',
        icon: UserGroupIcon,
    },
    {
        name: 'Sizes',
        to: 'single-print-sizes',
        icon: PhotoIcon,
    },
    {
        name: 'Wraps',
        to: 'single-print-wraps',
        icon: Square2StackIcon,
    },
    {
        name: 'Borders & Frames',
        to: 'single-print-borders',
        icon: Square3Stack3DIcon,
    },
    {
        name: 'Hardware Items',
        to: 'hardware-items',
        icon: ScissorsIcon,
    },
    {
        name: 'Other Prices',
        to: 'other-prices',
        icon: CurrencyDollarIcon,
    },
];

export function AdminNavigation() {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const setUser = useAuthStore((state) => state.setUser);

    async function signOut() {
        try {
            await Auth.signOut();
            baseApiInterceptors.removeAuthHeaderInterceptor();
            localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_DATA);
            setUser(null);

            navigate('/');
        } catch (err: any) {
            console.log(err);
        }
    }

    return (
        <>
            <Transition.Root show={isSidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setIsSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setIsSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src="/acp-logo.jpeg"
                                            alt=""
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {navigationItems.map((navItem) => (
                                                        <li key={navItem.name}>
                                                            <NavLink
                                                                to={navItem.to}
                                                                className={({ isActive }) => classNames(
                                                                    isActive
                                                                        ? 'bg-gray-50 text-blue-600'
                                                                        : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}
                                                            >
                                                                {({ isActive }) => (
                                                                    <>
                                                                        <navItem.icon
                                                                            className={classNames(
                                                                                isActive ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                                                                                'h-6 w-6 shrink-0'
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                        {navItem.name}
                                                                    </>
                                                                )}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                    <div className="flex h-20 shrink-0 items-center">
                        <img
                            className="w-auto h-16"
                            src="/acp-logo.jpeg"
                            alt=""
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigationItems.map((navItem) => (
                                        <li key={navItem.name}>
                                            <NavLink
                                                to={navItem.to}
                                                className={({ isActive }) => classNames(
                                                    isActive
                                                        ? 'bg-gray-50 text-blue-600'
                                                        : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                {({ isActive }) => (
                                                    <>
                                                        <navItem.icon
                                                            className={classNames(
                                                                isActive ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                                                                'h-6 w-6 shrink-0'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {navItem.name}
                                                    </>
                                                )}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="-mx-6 mt-auto flex flex-col">
                                <button
                                    className="flex items-center gap-x-2 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                                    type="button"
                                    onClick={signOut}
                                >
                                    <ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="sr-only">Logout</span>
                                    <span aria-hidden="true">Logout</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">ACP Dashboard</div>
                <a href="#">
                    <span className="sr-only">Your profile</span>
                    <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </a>
            </div>
        </>
    );
}
