import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { useCalculateSubTotal } from '../../utils/order-price.util';

export function AppHeader() {
  const subtotal = useCalculateSubTotal();
  
  return (
    <header className="w-full">
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <button
          type="button"
          className="hidden border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex flex-1 justify-end px-4 sm:px-6">
        </div>

        <div className="px-4 flex items-center font-medium">Subtotal: ${subtotal}</div>
      </div>
    </header>
  );
}
