import { axiosBaseApiClient } from "./axios-client";
import { SinglePrintDetailsDto } from "../modules/canvas/dto/canvas.dto";
import { GalleryItemsDataDto } from "../modules/canvas/dto/gallery.dto";
import { ApiResponse } from "./api-response.model";
import { CreateSinglePrintOrderDto } from "../modules/canvas/dto/create-single-print-order.dto";

async function apiGet<T = any>(path: string): Promise<ApiResponse<T>> {
    try {
        const response = await axiosBaseApiClient.get(path);

        return response.data;
    } catch (err: any) {
        throw err;
    }
}

async function apiGetWithParams<T = any>(path: string, params: any): Promise<ApiResponse<T>> {
    try {
        const response = await axiosBaseApiClient.get(path, {
            params: params,
        });

        return response.data;
    } catch (err: any) {
        throw err;
    }
}

async function apiPost<T = any>(path: string, data?: any): Promise<ApiResponse<T>> {
    try {
        const response = await axiosBaseApiClient.post(path, data);

        return response.data;
    } catch (err: any) {
        throw err;
    }
}

async function apiPut<T = any>(path: string, data?: any): Promise<ApiResponse<T>> {
    try {
        const response = await axiosBaseApiClient.put(path, data);

        return response.data;
    } catch (err: any) {
        throw err;
    }
}

async function apiDelete<T = any>(path: string): Promise<ApiResponse<T>> {
    try {
        const response = await axiosBaseApiClient.delete(path);

        return response.data;
    } catch (err: any) {
        throw err;
    }
}

export const baseApi = {
    get: apiGet,
    getWithParams: apiGetWithParams,
    post: apiPost,
    put: apiPut,
    delete: apiDelete,
} as const;

export async function getSinglePrintDetails(): Promise<SinglePrintDetailsDto> {
    try {
        const response = await axiosBaseApiClient.get(`/canvas/single-print-details`);

        if (response.data?.success) {
            return response.data?.data;
        }

        throw new Error('Error getting canvas item data');
    } catch (err: any) {
        throw err?.response?.data || err;
    }
}

export async function getGalleryItems(): Promise<GalleryItemsDataDto> {
    try {
        const response = await axiosBaseApiClient.get(`/canvas/gallery-items`);

        if (response.data?.success) {
            return response.data?.data;
        }

        throw new Error('Error getting gallery items');
    } catch (err: any) {
        throw err?.response?.data || err;
    }
}

export async function createSinglePrintOrder(data: CreateSinglePrintOrderDto): Promise<ApiResponse> {
    try {
        const res = await axiosBaseApiClient.post(`/canvas/orders`, data);

        return res.data;
    } catch (err: any) {
        throw err?.response?.data || err;
    }
}

export async function createSinglePrintOrderForUser(data: CreateSinglePrintOrderDto): Promise<ApiResponse> {
    try {
        const res = await axiosBaseApiClient.post(`/canvas/orders/user`, data);

        return res.data;
    } catch (err: any) {
        throw err?.response?.data || err;
    }
}
