import { LAMINATE_PRICE_PER_SQFT, TAX_PERCENTAGE, MIN_PACKAGING_PRICE, PACKAGING_PRICE_PER_SQFT } from "../constants/data.constant";
import { SinglePrintBorder, SinglePrintSize, SinglePrintWrap } from "../modules/canvas/models";
import { CanvasOrderItem, useCanvasOrderStore } from "../stores/canvas/canvas-order.store";

export function toNumberTwoDigits(num: number) {
    return Number(num.toFixed(2));
}

export function calculateBorderPrice(border: SinglePrintBorder, size: SinglePrintSize, wrap: SinglePrintWrap): number {
    const borderWrap = border.borderWraps.find((borderWrap) => borderWrap.wrapId === wrap.id);

    if (!borderWrap) {
        return 0;
    }

    const totalInchesForBorder = (size.width * 2) + (size.height * 2);
    const totalFeetForBorder = totalInchesForBorder / 12;
    const priceForBorder = totalFeetForBorder * borderWrap.pricePerFoot;

    return priceForBorder;
}

export function calculateOrderItemPrice(orderItem: CanvasOrderItem): number {
    let priceForOrderItem = 0;

    if (orderItem.canvasDetails.size) {
        if (orderItem.canvasDetails.wrap) {
            let wrapId = orderItem.canvasDetails.wrap.id;
            const canvasPriceData = orderItem.canvasDetails.size.sizeWraps.find((sizeWrap) => sizeWrap.wrapId === wrapId);

            if (canvasPriceData) {
                priceForOrderItem += canvasPriceData.price;
            }
        }
    }

    if (orderItem.canvasDetails.border) {
        if (orderItem.canvasDetails.size && orderItem.canvasDetails.wrap) {
            let borderPrice = calculateBorderPrice(orderItem.canvasDetails.border, orderItem.canvasDetails.size, orderItem.canvasDetails.wrap);
            priceForOrderItem += borderPrice;
        }
    }

    if (orderItem.canvasDetails.hardwareItem) {
        let hardwarePrice = orderItem.canvasDetails.hardwareItem.price;
        priceForOrderItem += hardwarePrice;
    }

    if (orderItem.canvasDetails.shouldLaminate) {
        if (orderItem.canvasDetails.size) {
            priceForOrderItem += calculateLaminatePrice(orderItem.canvasDetails.size, LAMINATE_PRICE_PER_SQFT);
        }
    }

    if (orderItem.canvasDetails.size) {
        priceForOrderItem += calculatePackagingPrice(orderItem.canvasDetails.size, PACKAGING_PRICE_PER_SQFT);
    }

    return priceForOrderItem * orderItem.quantity;
}

export function useCalculateSubTotal() {
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    let subTotal = 0;

    orderItems.forEach((orderItem) => {
        subTotal += calculateOrderItemPrice(orderItem);
    });

    return toNumberTwoDigits(subTotal);
}

export function calculateOrderPrice(subtotal: number, shippingPrice: number, discountPercentage: number) {
    const taxAmount = subtotal * (TAX_PERCENTAGE / 100);
    const discountAmount = subtotal * (discountPercentage / 100);
    const totalPrice = subtotal + shippingPrice + taxAmount - discountAmount;

    return {
        totalPrice: toNumberTwoDigits(totalPrice),
        taxAmount: toNumberTwoDigits(taxAmount),
        discountAmount: toNumberTwoDigits(discountAmount),
    };
}

export function calculateShippingPrice(orderItems: CanvasOrderItem[]): number {
    let shippingPrice = 0;

    orderItems.forEach((orderItem) => {
        shippingPrice += calculateOrderItemShippingPrice(orderItem);
    });

    return toNumberTwoDigits(shippingPrice);
}

export function calculatePackagingPrice(size: SinglePrintSize, packagingPricePerSqFt: number): number {
    const totalSqInches = size.width * size.height;
    const totalSqFt = totalSqInches / 144;
    const totalPackagingPrice = totalSqFt * packagingPricePerSqFt;

    if (totalPackagingPrice < MIN_PACKAGING_PRICE) {
        return MIN_PACKAGING_PRICE;
    }

    return toNumberTwoDigits(totalPackagingPrice);
}

export function calculateLaminatePrice(size: SinglePrintSize, laminatePricePerSqFt: number): number {
    const totalSqInches = size.width * size.height;
    const totalSqFt = totalSqInches / 144;
    const totalLaminatePrice = totalSqFt * laminatePricePerSqFt;

    return toNumberTwoDigits(totalLaminatePrice);
}

export function calculateOrderItemShippingPrice(orderItem: CanvasOrderItem): number {
    if (!orderItem.canvasDetails.size) {
        return 0;
    }

    const totalInches = (orderItem.canvasDetails.size.width * 2) + (orderItem.canvasDetails.size.height * 2);
    let shippingPricePerItem = 0;

    if (totalInches <= 72) {
        shippingPricePerItem = 15;
    } else if (totalInches <= 100) {
        shippingPricePerItem = 25;
    } else if (totalInches <= 140) {
        shippingPricePerItem = 40;
    } else if (totalInches <= 180) {
        shippingPricePerItem = 60;
    } else {
        // TODO: need to display additional shipping charges may be applied for larger sizes
        shippingPricePerItem = 60;
    }

    const totalShippingPriceForOrderItem = shippingPricePerItem * orderItem.quantity;

    return toNumberTwoDigits(totalShippingPriceForOrderItem);
}
