import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { singlePrintBordersApi } from "../api/single-print-borders.api";
import { SinglePrintBorderWrap } from "../../canvas/models/single-print-border.model";
import { QUERY_KEYS } from "../../../common/query-keys";
import { UpdateSinglePrintBorderDto } from "../api/dto";

export function EditSinglePrintBorder() {
    const params = useParams();
    const borderId = parseInt(params.id!);
    const [name, setName] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>();
    const [borderWraps, setBorderWraps] = useState<SinglePrintBorderWrap[]>([]);

    const queryClient = useQueryClient();
    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_BORDER_BY_ID, borderId],
        queryFn: () => {
            return singlePrintBordersApi.getSinglePrintBorderById(borderId);
        },
        enabled: !!borderId,
        onSuccess: (res) => {
            const borderDetails = res.data;

            setName(borderDetails.name);
            setImageUrl(borderDetails.imageUrl);
            setBorderWraps(borderDetails.borderWraps);
        },
        onError: () => {
            toast.error(`Couldn't get border details`);
        },
    });
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    function onPriceChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const price = isNaN(e.target.valueAsNumber) ? '' as any : e.target.valueAsNumber;

        const newBorderWraps = [...borderWraps];
        newBorderWraps[index].pricePerFoot = price;
        setBorderWraps(newBorderWraps);
    }

    function onWholesalePriceChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const price = isNaN(e.target.valueAsNumber) ? '' as any : e.target.valueAsNumber;

        const newBorderWraps = [...borderWraps];
        newBorderWraps[index].wholesalePricePerFoot = price;
        setBorderWraps(newBorderWraps);
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateSinglePrintBorder({
            name: name,
            borderWraps: borderWraps,
        });
    }

    async function updateSinglePrintBorder(updateData: UpdateSinglePrintBorderDto) {
        setIsLoading(true);

        try {
            const res = await singlePrintBordersApi.updateSinglePrintBorder(borderId, updateData);

            if (res.success) {
                toast.success(`Successfully updated border details`);
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_BORDER_BY_ID, borderId] });
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_BORDERS] });
                return;
            }

            toast.error(`Couldn't update border details`);
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't update border details`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Edit border details</h2>

            <form className="mt-8 space-y-2" onSubmit={onSubmit}>
                <div>
                    <img
                        className="w-auto h-36"
                        src={imageUrl}
                        alt=""
                    />
                </div>

                <div className="grid grid-cols-3">
                  <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="name">Name</label>
                      <input
                          id="name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                      />
                  </div>
                </div>

                <div>
                    <h4 className="mt-4 font-semibold text-lg text-gray-900">Prices</h4>
                </div>

                <div className="grid sm:grid-cols-2 sm:gap-x-3 gap-y-6">
                    {borderWraps.map((borderWrap, index) => (
                        <div key={index}>
                            <div>
                                <img
                                    src={borderWrap.wrap?.imageUrl}
                                    className="mb-1 w-auto h-32"
                                />
                            </div>

                            <div className="mt-2.5">
                                <label
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                    htmlFor={`border-${borderWrap.wrapId}-price`}
                                >
                                    {borderWrap.wrap?.name} price ($) per foot
                                </label>

                                <div className="mt-1 w-24">
                                    <input
                                        id={`border-${borderWrap.wrapId}-price`}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        value={borderWrap.pricePerFoot}
                                        onChange={(e) => onPriceChange(e, index)}
                                    />
                                </div>
                            </div>

                            <div className="mt-2.5">
                                <label
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                    htmlFor={`border-${borderWrap.wrapId}-wholesale-price`}
                                >
                                    {borderWrap.wrap?.name} wholesale/PAM price ($) per foot
                                </label>

                                <div className="w-24">
                                    <input
                                        id={`border-${borderWrap.wrapId}-wholesale-price`}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        value={borderWrap.wholesalePricePerFoot}
                                        onChange={(e) => onWholesalePriceChange(e, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div>
                    <button
                        className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
