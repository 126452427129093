import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AdminNavigation } from "./admin-navigation.component";
import { useAuthStore } from "../../../stores/auth.store";
import { useLoaderStore } from "../../../stores/loader.store";
import { UserRole } from "../../users/models/user.model";

export function AdminLayout() {
    const isLoadingAuth = useAuthStore((state) => state.isLoadingAuth);
    const user = useAuthStore((state) => state.user);
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoadingAuth);
    }, [isLoadingAuth]);

    if (isLoadingAuth) {
        return null;
    }

    if (!user) {
        return (<Navigate to="/login" />);
    }

    if (user.role !== UserRole.ADMIN) {
        return (<Navigate to="/" />);
    }

    return (
        <div>
            <AdminNavigation />

            <main className="py-10 lg:pl-72">
                <div className="px-4 sm:px-6 lg:px-8">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
