import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { getGalleryItems } from "../api/base-api";
import { GalleryItemsDataDto } from "../modules/canvas/dto/gallery.dto";
import { QUERY_KEYS } from "../common/query-keys";

export function useGetGalleryItems() {
    const { data, isLoading } = useQuery<GalleryItemsDataDto>({
        queryKey: [QUERY_KEYS.GALLERY_ITEMS],
        queryFn: getGalleryItems,
        onError: () => {
            toast.error(`Couldn't get gallery items`);
        },
    });

    return {
        galleryItems: data?.ourGalleryItems,
        isLoading: isLoading,
    };
}
