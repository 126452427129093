import { BORDER_IDS } from "../constants/data.constant";
import { CanvasOrderItem, DeliveryMethod, ShippingInfo } from "../stores/canvas/canvas-order.store";

const LINE_BREAK = '\n';
const HORIZONTAL_SEPERATOR_LINE = '-----------------------------------------------------------------------';

export function generateOrderDetailsText(orderItems: CanvasOrderItem[], shippingInfo: ShippingInfo, croppedFileUrls: string[], deliveryMethod: DeliveryMethod): string {
    let orderDetailsText = '';

    orderItems?.forEach((orderItem, index) => {
        orderDetailsText += HORIZONTAL_SEPERATOR_LINE;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Order Item ${index + 1}`;
        orderDetailsText += LINE_BREAK;

        // image details
        orderDetailsText += 'Image Details';
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Original Image: ${orderItem.imgSrc}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Cropped Image: ${croppedFileUrls[index]}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        // crop details
        orderDetailsText += 'Crop Details:';
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `x: ${orderItem.cropDetails?.x}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `y: ${orderItem.cropDetails?.y}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `width: ${orderItem.cropDetails?.width}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `height: ${orderItem.cropDetails?.height}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        // canvas order details
        orderDetailsText += 'Canvas Order Details';
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Size:`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Width: ${orderItem.canvasDetails.size?.width}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Height: ${orderItem.canvasDetails.size?.height}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Wrap: ${orderItem.canvasDetails.wrap?.name}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Border: ${orderItem.canvasDetails.border?.name || ''}`;
        orderDetailsText += LINE_BREAK;

        if (orderItem.canvasDetails.border?.id === BORDER_IDS.BORDER_COLOUR) {
            orderDetailsText += `Border Colour: ${orderItem.canvasDetails.borderColour}`;
            orderDetailsText += LINE_BREAK;
        }

        orderDetailsText += `Hardware Item: ${orderItem.canvasDetails.hardwareItem?.name || ''}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Colour Finishing: ${orderItem.canvasDetails.colourFinishing?.name || ''}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Laminate: ${orderItem.canvasDetails.shouldLaminate ? "Yes" : "No"}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Minor Photo Retouching:`;
        orderDetailsText += LINE_BREAK;
        orderItem.canvasDetails.photoRetouchings?.forEach((photoRetouching) => {
            orderDetailsText += `${photoRetouching}`;
            orderDetailsText += LINE_BREAK;
        });

        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Retouching Description:`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += orderItem.canvasDetails.retouchingDescription || '';
        orderDetailsText += LINE_BREAK;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `Quantity: ${orderItem.quantity}`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += LINE_BREAK;
        orderDetailsText += LINE_BREAK;
    });

    orderDetailsText += HORIZONTAL_SEPERATOR_LINE;
    orderDetailsText += LINE_BREAK;
    orderDetailsText += HORIZONTAL_SEPERATOR_LINE;
    orderDetailsText += LINE_BREAK;

    orderDetailsText += `Delivery method: ${deliveryMethod}`;
    orderDetailsText += LINE_BREAK;
    orderDetailsText += LINE_BREAK;

    if (deliveryMethod === DeliveryMethod.SHIPPING) {
        orderDetailsText += `Shipping Info:`;
        orderDetailsText += LINE_BREAK;

        orderDetailsText += `First name: ${shippingInfo.firstName}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Last name: ${shippingInfo.lastName}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Address: ${shippingInfo.address}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Apartment: ${shippingInfo.apartment}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `City: ${shippingInfo.city}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Country: ${shippingInfo.country}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `State: ${shippingInfo.state}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Postal code: ${shippingInfo.postalCode}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Phone number: ${shippingInfo.phoneNumber}`;
        orderDetailsText += LINE_BREAK;
        orderDetailsText += `Email: ${shippingInfo.email}`;
        orderDetailsText += LINE_BREAK;
    }


    return orderDetailsText;
}
