import { SinglePrintWrap } from "./single-print-wrap.model";

export class SinglePrintSize {
    id!: number;
    width!: number;
    height!: number;
    sizeWraps!: SinglePrintSizeWrap[];
    tag!: SinglePrintSizeTag;
}

export class SinglePrintSizeWrap {
    sizeId!: number;
    wrapId!: number;
    price!: number;
    wholesalePrice!: number;

    wrap!: SinglePrintWrap;
}

export enum SinglePrintSizeTag {
    "Square" = "Square",
    "Panoramic" = "Panoramic",
    "Potrait" = "Potrait",
    "Landscape" = "Landscape",
}

export const SINGLE_PRINT_SIZE_TAGS = Object.values(SinglePrintSizeTag);
