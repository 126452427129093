import create from "zustand";
import { GalleryItem } from "../modules/canvas/models";

type CanvasNavigationSection = 'image' | 'size' | 'wrap' | 'border' | 'hardware' | 'finishing' | 'other' | 'checkout';

interface CanvasNavigationState {
    currentTab: CanvasNavigationSection;
    selectedGalleryItem: GalleryItem | null;
    setCurrentTab: (size: CanvasNavigationSection) => void;
    setSelectedGalleryItem: (galleryItem: GalleryItem | null) => void;
}

export const useCanvasNavigationStore = create<CanvasNavigationState>((set) => ({
    currentTab: 'image',
    selectedGalleryItem: null,
    setCurrentTab: (tab) => {
        set(() => ({
            currentTab: tab
        }));
    },
    setSelectedGalleryItem: (galleryItem) => {
        set(() => ({
            selectedGalleryItem: galleryItem
        }));
    },
}));
