import { useEffect } from 'react';
import { classNames } from '../../../utils/class-names.util';
import { DeliveryMethod, useCanvasOrderStore } from '../../../stores/canvas/canvas-order.store';

export function ShippingInfo() {
    const deliveryMethod = useCanvasOrderStore((state) => state.deliveryMethod);
    const shippingInfo = useCanvasOrderStore((state) => state.shippingInfo);
    const setDeliveryMethod = useCanvasOrderStore((state) => state.setDeliveryMethod);
    const setShippingInfo = useCanvasOrderStore((state) => state.setShippingInfo);

    useEffect(() => {
        if (shippingInfo.postalCode?.length > 4) {
            // get shipping rates
        }
    }, [shippingInfo.postalCode]);

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Delivery Method</h4>

            <div className="mt-3">
                <div className="grid grid-cols-3 gap-2">
                    <button
                        type="button"
                        className={classNames(
                            'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150 font-medium',
                            deliveryMethod === DeliveryMethod.SHIPPING ? 'border-blue-500 bg-blue-50' : 'border-blue-900/10 hover:bg-blue-50 hover:border-blue-500'
                        )}
                        onClick={() => setDeliveryMethod(DeliveryMethod.SHIPPING)}
                    >
                        <div className="font-medium text-md">To Your Address</div>
                    </button>

                    <button
                        type="button"
                        className={classNames(
                            'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150 font-medium',
                            deliveryMethod === DeliveryMethod.PICKUP ? 'border-blue-500 bg-blue-50' : 'border-blue-900/10 hover:bg-blue-50 hover:border-blue-500'
                        )}
                        onClick={() => setDeliveryMethod(DeliveryMethod.PICKUP)}
                    >
                        <div className="font-medium text-md">Store Pickup</div>
                    </button>
                </div>
            </div>

            {deliveryMethod === DeliveryMethod.PICKUP && (
                <div className="mt-5">
                    <h4 className="mb-4 text-xl font-semibold">Self Pickup Location</h4>

                    <address className="font-medium not-italic leading-normal text-gray-800">
                        American Canvas Prints<br />
                        406 E Commonwealth Ave Suite 6<br />
                        Fullerton, CA 92832<br />
                        USA
                    </address>
                </div>
            )}

            {deliveryMethod === DeliveryMethod.SHIPPING && (
                <div className="mt-5">
                    <h4 className="mb-3 text-xl font-semibold">Delivery Information</h4>

                    <div className="mt-4 grid sm:grid-cols-2 sm:gap-x-4 gap-y-4">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                First name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="first-name"
                                    name="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.firstName}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, firstName: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Last name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="last-name"
                                    name="last-name"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.lastName}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, lastName: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                Address
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    autoComplete="street-address"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.address}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, address: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                                Apartment, suite, etc.
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="apartment"
                                    id="apartment"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.apartment}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, apartment: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.city}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, city: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Country
                            </label>
                            <div className="mt-1">
                                <select
                                    id="country"
                                    name="country"
                                    autoComplete="country-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.country}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, country: e.target.value })}
                                >
                                    <option value="US">United States</option>
                                    {/* <option value="CA">Canada</option> */}
                                    {/* <option value="MX">Mexico</option> */}
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                State / Province
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="region"
                                    id="region"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.state}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, state: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Postal code
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.postalCode}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, postalCode: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                Phone number
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    autoComplete="tel"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.phoneNumber}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, phoneNumber: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    value={shippingInfo.email}
                                    onChange={(e) => setShippingInfo({ ...shippingInfo, email: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
