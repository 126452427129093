import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { useLoaderStore } from "../../../stores/loader.store";
import { classNames } from "../../../utils/class-names.util";
import { generateS3ImageUploadKey, uploadFileToS3 } from '../../../utils/s3.util';
import { QUERY_KEYS } from "../../../common/query-keys";
import { currentUserApi } from "../../users/api/current-user.api";
import { useAuthStore } from "../../../stores/auth.store";
import { generateRandomStringId } from "../../../utils/random-id.util";

export function SelectImage() {
    const queryClient = useQueryClient();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const user = useAuthStore((state) => state.user);
    const setImgSrc = useCanvasOrderStore((state) => state.setImgSrc);
    const setCrop = useCanvasOrderStore((state) => state.setCrop);
    const addNewItem = useCanvasOrderStore((state) => state.addNewItem);
    const removeItem = useCanvasOrderStore((state) => state.removeItem);
    const setSelectedOrderItemIndex = useCanvasOrderStore((state) => state.setSelectedOrderItemIndex);
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    const { data: userGalleryImagesRes, isLoading: isLoadingUserGalleryImages } = useQuery({
        queryKey: [QUERY_KEYS.USER_GALLERY_IMAGES, user?.id],
        queryFn: currentUserApi.getUserGalleryImages,
        onError: () => {
            toast.error(`Couldn't get your gallery images`);
        },
        enabled: !!user,
    });
    const userGalleryImages = userGalleryImagesRes?.data || [];

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);

            const file = e.target.files[0];

            if (file) {
                uploadImage(file);
                // uploadImageMock(file);
            }

            e.target.value = null as any;
        }
    }

    async function uploadImage(file: File) {
        const fileExt = file.name.split('.').pop();
        const uploadKey = generateS3ImageUploadKey(fileExt, 'original');

        setIsLoading(true, 'Uploading image...');

        try {
            const imageUrl = await uploadFileToS3(file, uploadKey);
            setImgSrc(imageUrl);

            if (user) {
                saveImageToUserGallery(imageUrl);
            }
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't upload image`);
        } finally {
            setIsLoading(false);
        }
    }

    async function saveImageToUserGallery(imageUrl: string) {
        try {
            await currentUserApi.createUserGalleryImage({
                imageCode: generateRandomStringId(),
                imageUrl: imageUrl,
            });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_GALLERY_IMAGES, user?.id] });
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't save image to your gallery`);
        } finally {
            setIsLoading(false);
        }
    }

    async function deleteImageFromUserGallery(userGalleryImageId: number) {
        try {
            await currentUserApi.deleteUserGalleryImage(userGalleryImageId);
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_GALLERY_IMAGES, user?.id] });
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't delete image from your gallery`);
        } finally {
            setIsLoading(false);
        }
    }

    // function uploadImageMock(file: File) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => {
    //         setImgSrc(reader.result?.toString() || '');
    //     });

    //     reader.readAsDataURL(file);
    // }

    return (
        <div className="space-y-3">
            <div>
                <h4 className="mb-2 text-xl font-semibold">Upload Photo:</h4>

                <label className="block">
                    <span className="sr-only">Choose photo</span>
                    <input
                        type="file"
                        className="block w-full text-sm text-slate-500 cursor-pointer
                        file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                        accept="image/*"
                        onChange={onSelectFile}
                    />
                </label>

                <div
                    className="mt-3 flex flex-wrap gap-2"
                >
                    {orderItems.map((orderItem, index) => (
                        <div
                            className="relative"
                            key={index}
                        >
                            <div
                                onClick={() => setSelectedOrderItemIndex(index)}
                                className={classNames(
                                    'w-28 h-28 relative cursor-pointer',
                                    selectedOrderItemIndex === index ? 'border-2 border-red-500' : '',
                                )}
                            >
                                {orderItem.imgSrc ? (
                                    <div
                                        className="w-full h-full relative flex items-center justify-center"
                                    >
                                        <img
                                            src={orderItem.imgSrc}
                                            className="w-full h-full"
                                            alt=""
                                        />
                                        <div className="absolute py-1 bottom-0 w-full bg-black bg-opacity-50 text-white font-semibold text-center text-xs">{index + 1}</div>
                                    </div>
                                ) : (
                                    <div
                                        className="w-full h-full relative flex items-center justify-center bg-gray-200 hover:bg-gray-300"
                                    >
                                        <div className="absolute mt-3">
                                            <svg className="w-8 h-8 mx-auto opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                            </svg>
                                            <div className="text-center text-xs text-gray-500">{index + 1}</div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button
                                className="absolute m-1.5 top-0 right-0 flex items-center justify-center w-5 h-5 bg-white shadow-sm rounded-sm"
                                type="button"
                                onClick={() => removeItem(index)}
                            >
                                <svg className="w-3 h-3 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    ))}

                    <button
                        className="border-2 w-28 h-28 relative flex items-center justify-center bg-gray-200 hover:bg-gray-300 border-blue-500"
                        type="button"
                        onClick={addNewItem}
                    >
                        <svg className="w-8 h-8 mx-auto opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </div>

                {isLoadingUserGalleryImages && (
                    <div>
                        {/* show small section wise spinner */}
                    </div>
                )}

                {user && (
                    <div className="mt-4">
                        <h5 className="mb-2 text-xl font-semibold">Your gallery</h5>

                        <div className="mt-3 flex flex-wrap gap-2">
                            {userGalleryImages.map((userGalleryImage) => (
                                <div
                                    key={userGalleryImage.id}
                                    className="relative"
                                >
                                    <div
                                        className="w-28 h-28 relative cursor-pointer"
                                        onClick={() => setImgSrc(userGalleryImage.imageUrl)}
                                    >
                                        <img
                                            src={userGalleryImage.imageUrl}
                                            className="w-full h-full"
                                            alt=""
                                        />
                                    </div>

                                    <button
                                        className="absolute m-1.5 top-0 right-0 flex items-center justify-center w-5 h-5 bg-white shadow-sm rounded-sm"
                                        type="button"
                                        onClick={() => deleteImageFromUserGallery(userGalleryImage.id)}
                                    >
                                        <svg className="w-3 h-3 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
