import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Header } from "../shared/components";
import { useAuthStore } from "../stores/auth.store";
import { useLoaderStore } from "../stores/loader.store";
import { baseApiInterceptors } from "../api/base-api.interceptor";
import { LOCAL_STORAGE_KEYS } from "../constants/local-storage.constant";
import { UserRole } from "../modules/users/models/user.model";
import { currentUserApi } from "../modules/users/api/current-user.api";

export function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const user = useAuthStore((state) => state.user);
    const isLoadingAuth = useAuthStore((state) => state.isLoadingAuth);
    const setFirstSignInTempUser = useAuthStore((state) => state.setFirstSignInTempUser);
    const setUser = useAuthStore((state) => state.setUser);
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [isLoadingAuth]);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        signIn();
    }

    async function signIn() {
        setIsLoading(true);

        try {
            const userSignInData = await Auth.signIn(email, password);

            handlePostSignIn(userSignInData);
        } catch (err: any) {
            console.log(err);
            setErrorMessage(parseCognitoSignInErrorMessage(err));
        } finally {
            setIsLoading(false);
        }
    }

    async function handlePostSignIn(userSignInData: any) {
        if (userSignInData.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setFirstSignInTempUser(userSignInData);
            return navigate('/change-initial-password');
        }

        baseApiInterceptors.addAuthHeaderInterceptor();

        const res = await currentUserApi.getProfile();

        if (res.success) {
            const userData = res.data;
            localStorage.setItem(LOCAL_STORAGE_KEYS.USER_DATA, JSON.stringify(userData));
            setUser(userData);

            if (userData.role === UserRole.ADMIN) {
                return navigate('/admin');
            }
        }

        navigate('/');
    }

    function parseCognitoSignInErrorMessage(err: any): string {
        let cognitoErrorMessage = err.message;
        let errorMesasge = 'Error signing in.';

        switch (cognitoErrorMessage) {
            case 'Incorrect username or password.': {
                errorMesasge = cognitoErrorMessage;
                break;
            }
            default:
                break;
        }

        return errorMesasge;
    }

    return (
        <div>
            <Header />

            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                <div className="text-sm">
                                    <a href="#" className="font-semibold text-blue-600 hover:text-blue-500 hover:underline">Forgot password?</a>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="text-red-500 text-sm">{errorMessage}</div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                            >Sign in</button>
                        </div>
                    </form>

                    <p className="mt-5 space-x-1 text-center text-sm text-gray-500">
                        <span>Not a member?</span>
                        <Link
                            to="/sign-up"
                            className="font-semibold leading-6 text-blue-600 hover:text-blue-500 hover:underline"
                        >Register</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
