import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Header } from "../shared/components";
import { useAuthStore } from "../stores/auth.store";
import { useLoaderStore } from "../stores/loader.store";

export function ChangeInitialPasswordPage() {
    const navigate = useNavigate();
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const firstSignInTempUser = useAuthStore((state) => state.firstSignInTempUser);
    const setFirstSignInTempUser = useAuthStore((state) => state.setFirstSignInTempUser);
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        if (!firstSignInTempUser) {
            navigate('/');
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        updatePassword();
    }

    async function updatePassword() {
        setIsLoading(true);

        try {
            await Auth.completeNewPassword(firstSignInTempUser, password);

            setFirstSignInTempUser(null);
            navigate('/login');
        } catch (err: any) {
            console.log(err);
            setErrorMessage(parseChangePasswordErrorMessage(err));
        } finally {
            setIsLoading(false);
        }
    }

    function parseChangePasswordErrorMessage(err: any): string {
        let cognitoErrorMessage: string = err.message;
        let errorMesasge = 'Error changing password.';

        const cognitoPasswordPolicyErrorMessagePrefix = 'Password does not conform to policy: ';

        if (cognitoErrorMessage?.startsWith(cognitoPasswordPolicyErrorMessagePrefix)) {
            errorMesasge = cognitoErrorMessage.slice(cognitoPasswordPolicyErrorMessagePrefix.length);
        }

        return errorMesasge;
    }

    return (
        <div>
            <Header />

            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">Change password</h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
                            <div className="mt-2">
                                <input
                                    id="confirm-password"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="text-red-500 text-sm">{errorMessage}</div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                            >Change Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
