import { classNames } from '../../../utils/class-names.util';
import { HardwareItem } from "../models";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { useGetCanvasHardwareItems } from '../../../hooks/use-get-canvas-product-details.hook';

export function SelectHardware() {
    const { hardwareItems } = useGetCanvasHardwareItems();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setHardwareItem = useCanvasOrderStore((state) => state.setHardwareItem);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedHardwareItem = selectedOrderItem.canvasDetails.hardwareItem;

    function onSelectHardwareItem(hardwareItem: HardwareItem) {
        setHardwareItem(hardwareItem);
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Select Hanging Materials</h4>

            <div className="grid grid-cols-3 gap-2">
                {hardwareItems?.map((hardwareItem) => (
                    <button
                        type="button"
                        key={hardwareItem.id}
                        onClick={() => onSelectHardwareItem(hardwareItem)}
                        className={classNames(
                            'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150',
                            selectedHardwareItem?.id === hardwareItem.id ? 'border-blue-500 bg-blue-900/5 font-medium' : 'border-blue-900/10 hover:bg-blue-900/10'
                        )}
                    >
                        <img
                            src={hardwareItem.imageUrl}
                            alt={hardwareItem.name}
                            className="w-auto h-28 rounded-sm"
                        />
                        <div className="mt-2 text-sm font-medium">{hardwareItem.name}</div>
                        {hardwareItem.price === 0 ? (
                            <div className="mt-1 text-sm">Free</div>
                        ) : (
                            <div className="mt-1 text-sm">${hardwareItem.price}</div>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
}
