import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { singlePrintBordersApi } from "../api/single-print-borders.api";
import { QUERY_KEYS } from "../../../common/query-keys";

function useGetSinglePrintBorders() {
    const { data, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_BORDERS],
        queryFn: singlePrintBordersApi.getSinglePrintBorders,
        onError: () => {
            toast.error(`Couldn't get border list`);
        },
    });

    return {
        borders: data?.data,
        isLoading: isLoading,
    };
}

export function SinglePrintBorderList() {
    const { borders, isLoading } = useGetSinglePrintBorders();
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Borders & Frames</h2>

            <div className="mt-8 grid grid-cols-2 sm:grid-cols-3 gap-x-3 gap-y-5">
                {borders?.map((border) => (
                    <div key={border.id}>
                        <img className="w-auto h-36" src={border.imageUrl} />

                        <p className="mt-2 block font-medium leading-6 text-gray-900">{border.name}</p>

                        <Link
                            to={`${border.id}/edit`}
                            className="text-blue-500 hover:text-blue-700 hover:underline"
                        >
                            Edit
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}
