import { UserGalleryImage } from "./user-gallery-image.model";

export class User {
    id!: number;
    email!: string;
    firstName!: string;
    lastName!: string;
    role!: UserRole;
    discountPercentage!: number;
    phoneNumber!: string;
    address!: string;
    businessName!: string;

    galleryImages?: UserGalleryImage[];
}

export enum UserRole {
    ADMIN = "ADMIN",
    PAM = "PAM",
}
