import { createPortal } from "react-dom";
import { useLoaderStore } from "../../stores/loader.store";

export function FullScreenLoader() {
    const isLoading = useLoaderStore((state) => state.isLoading);
    const loadingText = useLoaderStore((state) => state.loadingText);

    if (!isLoading) {
        return null;
    }

    return createPortal(
        <div className="fixed inset-0 flex flex-col flex-wrap items-center justify-center bg-black/75 transition-opacity z-50">
            <div className="loader"></div>

            <p className="font-semibold text-white">{loadingText || "Loading..."}</p>
        </div>,
        document.getElementById('full-screen-loader-portal')!
    );
}
