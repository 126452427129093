import create from "zustand";

interface LoaderState {
    isLoading: boolean;
    loadingText: string;
    setIsLoading: (isLoading: boolean, loadingText?: string) => void;
    setLoadingText: (text: string) => void;
}

export const useLoaderStore = create<LoaderState>((set) => ({
    isLoading: false,
    loadingText: '',
    setIsLoading: (isLoading, loadingText) => {
        set(() => ({
            isLoading: isLoading,
            loadingText: loadingText ?? '',
        }));
    },
    setLoadingText: (text) => {
        set(() => ({
            loadingText: text,
        }));
    },
}));
