import { Link, useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useAuthStore } from "../../stores/auth.store";
import { baseApiInterceptors } from "../../api/base-api.interceptor";
import { LOCAL_STORAGE_KEYS } from "../../constants/local-storage.constant";

export function Header() {
    const navigate = useNavigate();
    const user = useAuthStore((state) => state.user);
    const setUser = useAuthStore((state) => state.setUser);

    async function signOut() {
        try {
            await Auth.signOut();
            baseApiInterceptors.removeAuthHeaderInterceptor();
            localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_DATA);
            setUser(null);

            navigate('/');
        } catch (err: any) {
            console.log(err);
        }
    }

    return (
        <header>
            <div className="relative bg-white">
                <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-2 sm:px-6 md:justify-start space-x-6 lg:px-8">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to='/'>
                            <span className="sr-only">American Canvas Prints</span>
                            <img className="h-20 w-auto" src="/acp-logo.jpeg" alt="" />
                        </Link>
                    </div>
                    <nav className="space-x-4 flex flex-1 items-center justify-end">
                        <Link to='/our-gallery' className="rounded-md border-2 border-yellow-500 px-4 py-2 text-sm font-medium bg-white hover:bg-yellow-500 text-yellow-600 hover:text-white shadow-sm transition delay-100">Canvas Gallery</Link>
                        {user ? (
                            <button
                                type="button"
                                className="rounded-md border-2 border-yellow-500 px-4 py-2 text-sm font-medium bg-white hover:bg-yellow-500 text-yellow-600 hover:text-white shadow-sm transition delay-100"
                                onClick={signOut}
                            >Logout</button>
                        ) : (
                            <Link to='/login' className="rounded-md border-2 border-yellow-500 px-4 py-2 text-sm font-medium bg-white hover:bg-yellow-500 text-yellow-600 hover:text-white shadow-sm transition delay-100">Login</Link>
                        )}
                        <Link to='/canvas/order' className="rounded-md border border-transparent px-4 py-2 text-sm font-medium bg-yellow-500 text-white shadow-sm">Order Custom Canvas</Link>
                    </nav>
                    <div className="flex items-center justify-end space-x-4">
                        <a href="tel:+1-714-770-0101">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>
                        </a>
                        <a href="mailto:info@americancanvasprints.com">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}
