export const config = {
    API_URL: process.env.REACT_APP_ACP_API_URL,
    aws: {
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
        },
        s3: {
            bucketName: process.env.REACT_APP_S3_BUCKET_NAME || '',
        },
        cognito: {
            REGION: process.env.REACT_APP_COGNITO_REGION || '',
            USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
            USER_POOL_APP_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID || '',
        },
    },
};
