import { Auth } from "aws-amplify";
import { axiosBaseApiClient } from "./axios-client";

let authHeaderInterceptor: number | null = null;

function addAuthHeaderInterceptor() {
    removeAuthHeaderInterceptor();

    authHeaderInterceptor = axiosBaseApiClient.interceptors.request.use(async function (config) {
        try {
            if (!config.headers) {
                config.headers = {};
            }

            const token = (await Auth.currentSession()).getIdToken().getJwtToken();

            config.headers['Authorization'] = `Bearer ${token}`;
        } catch (err: any) {
            console.log('Error getting token');
            console.log(err);
        }

        return config;
    });
}

function removeAuthHeaderInterceptor() {
    if (authHeaderInterceptor !== null) {
        axiosBaseApiClient.interceptors.request.eject(authHeaderInterceptor);
        authHeaderInterceptor = null;
    }
}

export const baseApiInterceptors = {
    addAuthHeaderInterceptor: addAuthHeaderInterceptor,
    removeAuthHeaderInterceptor: removeAuthHeaderInterceptor,
} as const;