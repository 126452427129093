import create from "zustand";
import { User } from "../modules/users/models/user.model";

interface AuthState {
    firstSignInTempUser: any | null;
    isLoadingAuth: boolean;
    user: User | null;
    setFirstSignInTempUser: (user: any | null) => void;
    setIsLoadingAuth: (isLoading: boolean) => void;
    setUser: (user: User | null) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
    firstSignInTempUser: false,
    isLoadingAuth: true,
    user: null,
    setFirstSignInTempUser: (user) => {
        set(() => ({
            firstSignInTempUser: user,
        }));
    },
    setIsLoadingAuth: (isLoading) => {
        set(() => ({
            isLoadingAuth: isLoading,
        }));
    },
    setUser: (user) => {
        set(() => ({
            user: user,
        }));
    },
}));
