import { baseApi } from "../../../api/base-api";
import { SinglePrintSize } from "../../canvas/models/single-print-size.model";
import { UpdateSinglePrintSizeDto } from "./dto";

async function getSinglePrintSizes() {
    return await baseApi.get<SinglePrintSize[]>('canvas/single-print-sizes');
}

async function getSinglePrintSizeById(sizeId: number) {
    return await baseApi.get<SinglePrintSize>(`canvas/single-print-sizes/${sizeId}`);
}

async function updateSinglePrintSize(sizeId: number, updateData: UpdateSinglePrintSizeDto) {
    return await baseApi.put(`canvas/single-print-sizes/${sizeId}`, updateData);
}

export const singlePrintSizesApi = {
    getSinglePrintSizes: getSinglePrintSizes,
    getSinglePrintSizeById: getSinglePrintSizeById,
    updateSinglePrintSize: updateSinglePrintSize,
} as const;
