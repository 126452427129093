import { useState } from "react";
import { classNames } from '../../../utils/class-names.util';
import { SinglePrintBorder, SinglePrintWrapType } from "../models";
import { BORDER_IDS } from "../../../constants/data.constant";
import { ColourPickerModal } from "../../../shared/components";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { useGetCanvasBorders } from "../../../hooks/use-get-canvas-product-details.hook";
import { toNumberTwoDigits } from "../../../utils/order-price.util";

export function SelectBorder() {
    const { canvasBorders } = useGetCanvasBorders();
    const [showColourPickerModal, setShowColourPickerModal] = useState(false);

    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setBorder = useCanvasOrderStore((state) => state.setBorder);
    const setBorderColour = useCanvasOrderStore((state) => state.setBorderColour);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedSize = selectedOrderItem.canvasDetails.size;
    const selectedBorder = selectedOrderItem.canvasDetails.border;
    const selectedWrap = selectedOrderItem.canvasDetails.wrap;
    const borderColour = selectedOrderItem.canvasDetails.borderColour;

    function onSelectBorder(border: SinglePrintBorder) {
        setBorder(border);

        if (border.id === BORDER_IDS.BORDER_COLOUR) {
            setBorderColour(borderColour || '#000000');
            setShowColourPickerModal(true);
        } else {
            setBorderColour('');
        }
    }

    function calculateBorderPrice(border: SinglePrintBorder): number {
        if (!selectedSize || !selectedWrap) {
            return 0;
        }

        const borderWrap = border.borderWraps.find((borderWrap) => borderWrap.wrapId === selectedWrap.id);

        if (!borderWrap) {
            return 0;
        }

        const totalInchesForBorder = (selectedSize.width * 2) + (selectedSize.height * 2);
        const totalFeetForBorder = totalInchesForBorder / 12;
        const priceForBorder = totalFeetForBorder * borderWrap.pricePerFoot;

        return toNumberTwoDigits(priceForBorder);
    }

    if (!selectedWrap) {
        return (
            <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                <span className="font-medium">No Canvas Wrap selected!</span> Select a wrap to see available borders/frames.
            </div>
        );
    }

    if (selectedWrap.type === SinglePrintWrapType.Roll) {
        return (
            <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                <span className="font-medium">No borders/frames for Roll Canvas!</span> Select a Gallery Wrap to see available borders/frames.
            </div>
        );
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Select Border &amp; Frame</h4>

            <ColourPickerModal
                show={showColourPickerModal}
                colour={borderColour}
                onColourChange={setBorderColour}
                onClose={() => {
                    setShowColourPickerModal(false);
                }}
            />

            <div className="grid grid-cols-3 gap-2">
                {canvasBorders?.map((border) => {
                    const borderPrice = calculateBorderPrice(border);

                    return (
                        <button
                            type="button"
                            key={border.id}
                            onClick={() => onSelectBorder(border)}
                            className={classNames(
                                'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150',
                                selectedBorder?.id === border.id ? 'border-blue-500 bg-blue-900/5 font-medium' : 'border-blue-900/10 hover:bg-blue-900/10'
                            )}
                        >
                            <img
                                src={border.imageUrl}
                                alt={border.name}
                                className="w-auto h-28 rounded-sm"
                            />
                            <div className="mt-2 text-sm font-medium">{border.name}</div>
                            {borderPrice === 0 ? (
                                <div className="mt-1 text-sm">Free</div>
                            ) : (
                                <div className="mt-1 text-sm">${borderPrice}</div>
                            )}
                        </button>
                    )
                })}
            </div>
        </div>
    );
}
