import { classNames } from '../../../utils/class-names.util';
import { FinishingItem } from "../models";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { useGetCanvasFinishingItems } from '../../../hooks/use-get-canvas-product-details.hook';

export function SelectFinishing() {
    const { colourFinishingItems } = useGetCanvasFinishingItems();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setColourFinishing = useCanvasOrderStore((state) => state.setColourFinishing);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedColourFinishing = selectedOrderItem.canvasDetails.colourFinishing;

    function onSelectColourFinishing(finishingItem: FinishingItem) {
        setColourFinishing(finishingItem);
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Select Finishing Options</h4>

            <div className="grid grid-cols-3 gap-2">
                {colourFinishingItems?.map((colourFinishing) => (
                    <button
                        type="button"
                        key={colourFinishing.id}
                        onClick={() => onSelectColourFinishing(colourFinishing)}
                        className={classNames(
                            'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150',
                            selectedColourFinishing?.id === colourFinishing.id ? 'border-blue-500 bg-blue-900/5 font-medium' : 'border-blue-900/10 hover:bg-blue-900/10'
                        )}
                    >
                        <img
                            src={colourFinishing.imageUrl}
                            alt={colourFinishing.name}
                            className="w-auto h-28 rounded-sm"
                        />
                        <div className="mt-2 text-sm font-medium">{colourFinishing.name}</div>
                    </button>
                ))}
            </div>
        </div>
    );
}
