import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { getSinglePrintDetails } from "../api/base-api";
import { useCanvasOrderStore } from "../stores/canvas/canvas-order.store";
import { QUERY_KEYS } from "../common/query-keys";

export function useGetSinglePrintDetails() {
    const setInitialSelections = useCanvasOrderStore((state) => state.setInitialSelections);

    const { data, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_DETAILS],
        queryFn: getSinglePrintDetails,
        staleTime: Infinity,
        onSuccess: (singlePrintDetails) => {
            setInitialSelections(singlePrintDetails);
        },
        onError: () => {
            toast.error(`Couldn't get product details`);
        },
    });

    return {
        singlePrintDetails: data,
        isLoading: isLoading,
    };
}

export function useGetCanvasSizes() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        canvasSizes: singlePrintDetails?.sizes,
    };
}

export function useGetCanvasWraps() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        canvasWraps: singlePrintDetails?.wraps,
    };
}

export function useGetCanvasBorders() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        canvasBorders: singlePrintDetails?.borders,
    };
}

export function useGetCanvasHardwareItems() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        hardwareItems: singlePrintDetails?.hardwareItems,
    };
}

export function useGetCanvasFinishingItems() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        colourFinishingItems: singlePrintDetails?.colourFinishingItems,
    };
}

export function useGetCanvasPhotoRetouchingOptions() {
    const { singlePrintDetails } = useGetSinglePrintDetails();

    return {
        photoRetouchingOptions: singlePrintDetails?.photoRetouchingOptions,
    };
}

// export function useGetOtherPrices() {
//     const { singlePrintDetails } = useGetSinglePrintDetails();

//     return {
//         otherPrices: singlePrintDetails?.otherPrices,
//     };
// }
