import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { QUERY_KEYS } from "../../../common/query-keys";
import { singlePrintWrapsApi } from "../api/single-print-wraps.api";

function useGetSinglePrintWraps() {
    const { data, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_WRAPS],
        queryFn: singlePrintWrapsApi.getSinglePrintWraps,
        onError: () => {
            toast.error(`Couldn't get wrap list`);
        },
    });

    return {
        wraps: data?.data,
        isLoading: isLoading,
    };
}

export function SinglePrintWrapList() {
    const { wraps, isLoading } = useGetSinglePrintWraps();
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Wraps</h2>

            <div className="mt-8 grid sm:grid-cols-3 sm:gap-x-3 gap-y-5">
                {wraps?.map((wrap) => (
                    <div key={wrap.id}>
                        <img className="w-auto h-32" src={wrap.imageUrl} />

                        <p className="mt-2 block font-medium leading-6 text-gray-900">{wrap.name}</p>

                        <Link
                            to={`${wrap.id}/edit`}
                            className="text-blue-500 hover:text-blue-700 hover:underline"
                        >
                            Edit
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}
