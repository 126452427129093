import {
  PhotoIcon,
  Square2StackIcon,
  AdjustmentsHorizontalIcon,
  MapIcon,
  CurrencyDollarIcon,
  WrenchScrewdriverIcon,
  ViewfinderCircleIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/outline';
import { classNames } from '../../utils/class-names.util';
import { useCanvasNavigationStore } from '../../stores/navigation.store';

export function LeftSidebar() {
  const currentTab = useCanvasNavigationStore((state) => state.currentTab);
  const setCurrentTab = useCanvasNavigationStore((state) => state.setCurrentTab);

  return (
    <div className="w-28 overflow-y-auto bg-indigo-700">
      <div className="flex w-full flex-col items-center py-2">
        <div className="px-2 flex flex-shrink-0 items-center text-white font-semibold text-lg">
          <img className="w-full h-auto rounded overflow-hidden" src="/acp-logo.jpeg" alt="" />
        </div>
        <div className="mt-3 w-full flex-1">
          <button
            type='button'
            onClick={() => setCurrentTab('image')}
            className={classNames(
              currentTab === "image" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <PhotoIcon
              className={classNames(
                currentTab === "image" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Image</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('size')}
            className={classNames(
              currentTab === "size" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <Square2StackIcon
              className={classNames(
                currentTab === "size" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Size</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('wrap')}
            className={classNames(
              currentTab === "wrap" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <MapIcon
              className={classNames(
                currentTab === "wrap" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Wrap</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('border')}
            className={classNames(
              currentTab === "border" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <ViewfinderCircleIcon
              className={classNames(
                currentTab === "border" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Border & Frames</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('hardware')}
            className={classNames(
              currentTab === "hardware" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <WrenchScrewdriverIcon
              className={classNames(
                currentTab === "hardware" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Hardware</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('finishing')}
            className={classNames(
              currentTab === "finishing" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <CursorArrowRaysIcon
              className={classNames(
                currentTab === "finishing" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Finishing</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('other')}
            className={classNames(
              currentTab === "other" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <AdjustmentsHorizontalIcon
              className={classNames(
                currentTab === "other" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Other</span>
          </button>
          <button
            type='button'
            onClick={() => setCurrentTab('checkout')}
            className={classNames(
              currentTab === "checkout" ? 'bg-white text-indigo-700' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
              'group w-full p-3 flex flex-col items-center text-xs font-medium'
            )}
          >
            <CurrencyDollarIcon
              className={classNames(
                currentTab === "checkout" ? 'text-indigo-600 group-hover:text-indigo-700' : 'text-indigo-300 group-hover:text-white',
                'h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="mt-2">Checkout</span>
          </button>
        </div>
      </div>
    </div>
  );
}
