import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { UpdateOtherPriceDto } from "../api/dto";
import { QUERY_KEYS } from "../../../common/query-keys";
import { otherPricesApi } from "../api/other-prices.api";
import { OtherPriceType } from "../../canvas/models";

function parseInputValueNumber(value: number) {
    return isNaN(value) ? '' as any : value;
}

export function EditOtherPrices() {
    const [laminatePrice, setLaminatePrice] = useState<number>(0);
    const [laminateWholesalePrice, setLaminateWholesalePrice] = useState<number>(0);
    const [packagingPrice, setPackagingPrice] = useState<number>(0);
    const [packagingWholesalePrice, setPackagingWholesalePrice] = useState<number>(0);
    const [minPackagingPrice, setMinPackagingPrice] = useState<number>(0);
    const [minPackagingWholesalePrice, setMinPackagingWholesalePrice] = useState<number>(0);

    const queryClient = useQueryClient();
    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.OTHER_PRICES],
        queryFn: otherPricesApi.getOtherPrices,
        onSuccess: (res) => {
            const otherPrices = res.data;

            setLaminatePrice(otherPrices.laminate.price);
            setLaminateWholesalePrice(otherPrices.laminate.wholesalePrice);
            setPackagingPrice(otherPrices.packaging.price);
            setPackagingWholesalePrice(otherPrices.packaging.wholesalePrice);
            setMinPackagingPrice(otherPrices.minPackaging.price);
            setMinPackagingWholesalePrice(otherPrices.minPackaging.wholesalePrice);
        },
        onError: () => {
            toast.error(`Couldn't get other price details`);
        },
    });
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    function onLaminateSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateOtherPrice({
            type: OtherPriceType.LAMINATE_PRICE_PER_SQFT,
            price: laminatePrice,
            wholesalePrice: laminateWholesalePrice,
        });
    }

    function onPackagingSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateOtherPrice({
            type: OtherPriceType.PACKAGING_PRICE_PER_SQFT,
            price: packagingPrice,
            wholesalePrice: packagingWholesalePrice,
        });
    }

    function onMinPackagingSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateOtherPrice({
            type: OtherPriceType.MIN_PACKAGING_PRICE,
            price: minPackagingPrice,
            wholesalePrice: minPackagingWholesalePrice,
        });
    }

    async function updateOtherPrice(updateData: UpdateOtherPriceDto) {
        setIsLoading(true);

        try {
            const res = await otherPricesApi.updateOtherPrice(updateData);

            if (res.success) {
                toast.success(`Successfully updated price`);
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OTHER_PRICES] });
                return;
            }

            toast.error(`Couldn't update price`);
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't update price`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Edit other prices</h2>

            <div className="grid sm:grid-cols-3 gap-2">
                <form className="" onSubmit={onLaminateSubmit}>
                    <div className="mt-2">
                        <p className="font-medium">Laminate (per Sq Ft)</p>
                    </div>

                    <div className="mt-2 space-y-2">
                        <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-price"
                            >
                                Price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={laminatePrice}
                                    onChange={(e) => setLaminatePrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div>

                        {/* <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-wholesale-price"
                            >
                                Wholesale/PAM price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-wholesale-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={laminateWholesalePrice}
                                    onChange={(e) => setLaminateWholesalePrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div> */}

                        <div>
                            <button
                                className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                type="submit"
                                disabled
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>

                <form className="" onSubmit={onPackagingSubmit}>
                    <div className="mt-2">
                        <p className="font-medium">Packaging (per Sq Ft)</p>
                    </div>

                    <div className="mt-2 space-y-2">
                        <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-price"
                            >
                                Price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={packagingPrice}
                                    onChange={(e) => setPackagingPrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div>

                        {/* <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-wholesale-price"
                            >
                                Wholesale/PAM price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-wholesale-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={packagingWholesalePrice}
                                    onChange={(e) => setPackagingWholesalePrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div> */}

                        <div>
                            <button
                                className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                type="submit"
                                disabled
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>

                <form className="" onSubmit={onMinPackagingSubmit}>
                    <div className="mt-2">
                        <p className="font-medium">Min packaging</p>
                    </div>

                    <div className="mt-2 space-y-2">
                        <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-price"
                            >
                                Price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={minPackagingPrice}
                                    onChange={(e) => setMinPackagingPrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div>

                        {/* <div>
                            <label
                                className="block text-sm font-medium leading-6 text-gray-900"
                                htmlFor="laminate-wholesale-price"
                            >
                                Wholesale/PAM price ($)
                            </label>

                            <div className="mt-1 w-24">
                                <input
                                    id="laminate-wholesale-price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={minPackagingWholesalePrice}
                                    onChange={(e) => setMinPackagingWholesalePrice(parseInputValueNumber(e.target.valueAsNumber))}
                                    disabled
                                />
                            </div>
                        </div> */}

                        <div>
                            <button
                                className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                type="submit"
                                disabled
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
