import { SinglePrintSizeTag } from "../modules/canvas/models";

export const BORDER_IDS = {
    BORDER_COLOUR: 3,
} as const;

export const CANVAS_SIZE_TAGS = Object.values(SinglePrintSizeTag);

export const LAMINATE_PRICE_PER_SQFT = 3.00;
export const PACKAGING_PRICE_PER_SQFT = 1.50;
export const MIN_PACKAGING_PRICE = 6.00;
export const TAX_PERCENTAGE = 8.68;
