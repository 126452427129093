import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { QUERY_KEYS } from "../../../common/query-keys";
import { singlePrintWrapsApi } from "../api/single-print-wraps.api";
import { UpdateSinglePrintWrapDto } from "../api/dto";

export function EditSinglePrintWrap() {
    const params = useParams();
    const wrapId = parseInt(params.id!);
    const [name, setName] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>();

    const queryClient = useQueryClient();
    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_WRAP_BY_ID, wrapId],
        queryFn: () => {
            return singlePrintWrapsApi.getSinglePrintWrapById(wrapId);
        },
        enabled: !!wrapId,
        onSuccess: (res) => {
            const hardwareItemDetails = res.data;

            setName(hardwareItemDetails.name);
            setImageUrl(hardwareItemDetails.imageUrl);
        },
        onError: () => {
            toast.error(`Couldn't get hardware item details`);
        },
    });
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateSinglePrintWrap({
            name: name,
            imageUrl: imageUrl,
        });
    }

    async function updateSinglePrintWrap(updateData: UpdateSinglePrintWrapDto) {
        setIsLoading(true);

        try {
            const res = await singlePrintWrapsApi.updateSinglePrintWrap(wrapId, updateData);

            if (res.success) {
                toast.success(`Successfully updated wrap details`);
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_WRAP_BY_ID, wrapId] });
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_WRAPS] });
                return;
            }

            toast.error(`Couldn't update wrap details`);
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't update wrap details`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Edit wrap details</h2>

            <form className="mt-8 space-y-2" onSubmit={onSubmit}>
                <div>
                    <img
                        className="w-auto h-36"
                        src={imageUrl}
                    />
                </div>

                <div className="grid sm:grid-cols-3">
                    <div>
                        <label
                            className="block text-sm font-medium leading-6 text-gray-900"
                            htmlFor="name"
                        >
                            Name
                        </label>
                        <input
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>

                <div>
                    <button
                        className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
