import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Header } from "../shared/components";
import { useLoaderStore } from "../stores/loader.store";
import { usersApi } from "../modules/users/api/users.api";

export function SignUpPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [businessName, setBusinessName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        signUp();
    }

    async function signUp() {
        setIsLoading(true);

        try {
            const res = await usersApi.registerUser({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                address: address,
                businessName: businessName,
            });

            if (res.success) {
                toast.success(`Successfully registered`);
                navigate('/login');
                return;
            }

            toast.error(`Couldn't register`);
            setErrorMessage(`Couldn't register`);
        } catch (err: any) {
            console.log(err);
            toast.error(`Couldn't register`);
            setErrorMessage(`Couldn't register`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Header />

            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">Sign up as a Premium Artist Member (PAM)</h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                            <div className="mt-2">
                                <input
                                    id="firstName"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                            <div className="mt-2">
                                <input
                                    id="lastName"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div>
                            <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                            <div className="mt-2">
                                <input
                                    id="phoneNumber"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="text"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div>
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">Address</label>
                            <div className="mt-2">
                                <input
                                    id="address"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div>
                            <label htmlFor="businessName" className="block text-sm font-medium leading-6 text-gray-900">Business Name</label>
                            <div className="mt-2">
                                <input
                                    id="businessName"
                                    className="w-full border-0 block px-3 py-3 rounded-md shadow-sm text-gray-900 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                                    type="text"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                />
                            </div>
                            {/* <div className="text-red-500 text-sm">{errorMessage}</div> */}
                        </div>

                        <div className="text-red-500 text-sm">{errorMessage}</div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                            >Sign up</button>
                        </div>
                    </form>

                    <p className="mt-5 space-x-1 text-center text-sm text-gray-500">
                        <span>Already a member?</span>
                        <Link
                            to="/login"
                            className="font-semibold leading-6 text-blue-600 hover:text-blue-500 hover:underline"
                        >Back to Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
