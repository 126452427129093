import { classNames } from '../../../utils/class-names.util';
import { SinglePrintWrap, SinglePrintWrapType } from "../models";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { useGetCanvasWraps } from '../../../hooks/use-get-canvas-product-details.hook';

export function SelectWrap() {
    const { canvasWraps } = useGetCanvasWraps();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setWrap = useCanvasOrderStore((state) => state.setWrap);
    const setBorder = useCanvasOrderStore((state) => state.setBorder);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedSize = selectedOrderItem.canvasDetails.size;
    const selectedWrap = selectedOrderItem.canvasDetails.wrap;

    const wrapsForSelectedSize = canvasWraps?.filter((wrap) => {
        const availableWrap = selectedSize?.sizeWraps?.find((sizeWrap) => sizeWrap.wrapId === wrap.id);

        return !!availableWrap;
    });

    function onSelectWrap(wrap: SinglePrintWrap) {
        setWrap(wrap);

        if (wrap.type === SinglePrintWrapType.Roll) {
            setBorder(null);
        }
    }

    if (!selectedSize) {
        return (
            <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                <span className="font-medium">No Canvas Size selected!</span> Select a size to see available wraps.
            </div>
        );
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Select Canvas Wrap</h4>

            <div className="grid grid-cols-3 gap-2">
                {wrapsForSelectedSize?.map((wrap) => (
                    <button
                        type="button"
                        key={wrap.id}
                        onClick={() => onSelectWrap(wrap)}
                        className={classNames(
                            'px-3 py-5 border-2 flex flex-col items-center justify-center rounded transition duration-150 font-medium',
                            selectedWrap?.id === wrap.id ? 'border-blue-500 bg-blue-900/5' : 'border-blue-900/10 hover:bg-blue-900/10'
                        )}
                    >
                        <img
                            src={wrap.imageUrl}
                            alt={wrap.name}
                            className="w-auto h-28 rounded-sm"
                        />
                        <div className="mt-2 text-sm">{wrap.name}</div>
                    </button>
                ))}
            </div>
        </div>
    );
}
