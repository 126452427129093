import S3 from 'aws-sdk/clients/s3';
import { config } from '../config';
import { generateRandomStringId } from './random-id.util';

const s3Bucket = new S3({
    credentials: {
        accessKeyId: config.aws.credentials.accessKeyId,
        secretAccessKey: config.aws.credentials.secretAccessKey,
    },
});

export async function uploadBlobToS3(blob: Blob, key: string): Promise<string> {
    const bucketName = config.aws.s3.bucketName;
    const params: S3.PutObjectRequest = {
        Bucket: bucketName,
        Key: key,
        ACL: 'public-read',
        Body: blob,
        ContentType: blob.type
    };

    await s3Bucket.putObject(params).promise();

    // https://acp-media.s3.amazonaws.com/2022/10/15/1665846110878-8D6Y3.jpeg
    const fileUrl = `https://${bucketName}.s3.amazonaws.com/${key}`;

    return fileUrl;
}

export async function uploadFileToS3(file: File, key: string): Promise<string> {
    const bucketName = config.aws.s3.bucketName;
    const params: S3.PutObjectRequest = {
        Bucket: bucketName,
        Key: key,
        ACL: 'public-read',
        Body: file,
        ContentType: file.type
    };

    await s3Bucket.putObject(params).promise();

    // https://acp-media.s3.amazonaws.com/2022/10/15/1665846110878-8D6Y3.jpeg
    const fileUrl = `https://${bucketName}.s3.amazonaws.com/${key}`;

    return fileUrl;
}

export function generateS3FileUploadKeys(fileExt: string = 'jpeg') {
    const currentDate = new Date();

    const year = currentDate.getUTCFullYear();
    const month = (currentDate.getUTCMonth() + 1); // month is 0 idexed
    const date = currentDate.getUTCDate();
    const timeMs = currentDate.getTime();

    let fileName = `${timeMs}-${generateRandomStringId(5)}`;

    // 2022/10/15/1665833562258-ps7U_.jpeg
    // const key = `${year}/${month}/${date}/${fileName}.${fileExt}`;

    return {
        originalImgKey: `${year}/${month}/${date}/${fileName}-original.${fileExt}`,
        croppedImgKey: `${year}/${month}/${date}/${fileName}-crop.${fileExt}`
    };
}

export function generateS3OrderDetailsFileUploadKey(fileExt: string = 'txt'): string {
    const currentDate = new Date();

    const year = currentDate.getUTCFullYear();
    const month = (currentDate.getUTCMonth() + 1); // month is 0 idexed
    const date = currentDate.getUTCDate();
    const timeMs = currentDate.getTime();

    let fileName = `${timeMs}-${generateRandomStringId(5)}`;

    return `order-details-files/${year}/${month}/${date}/${fileName}.${fileExt}`;
}

/**
 * 2022/10/15/1665833562258-ps7U_.jpeg
 */
export function generateS3ImageUploadKey(fileExt: string = 'jpeg', fileNameSuffix?: string) {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    const month = (currentDate.getUTCMonth() + 1); // month is 0 indexed
    const date = currentDate.getUTCDate();
    const timeMs = currentDate.getTime();

    let fileName = `${timeMs}-${generateRandomStringId(5)}`;

    if (fileNameSuffix) {
        fileName += `-${fileNameSuffix}`;
    }

    return `${year}/${month}/${date}/${fileName}.${fileExt}`;
}
