import React from "react";
import ReactCrop, { PixelCrop, convertToPixelCrop } from 'react-image-crop';
import { CanvasCropDetails, useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { getCenterAspectCrop } from "../../../utils/get-center-aspect-crop";

import 'react-image-crop/dist/ReactCrop.css';

interface ImageCropProps {
}

export const ImageCrop = React.forwardRef<HTMLImageElement, ImageCropProps>((_, ref) => {
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setCropDetails = useCanvasOrderStore((state) => state.setCropDetails);
    const setCrop = useCanvasOrderStore((state) => state.setCrop);
    const setCompletedCrop = useCanvasOrderStore((state) => state.setCompletedCrop);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedImageSrc = selectedOrderItem.imgSrc;
    const crop = selectedOrderItem.crop;
    const selectedSize = selectedOrderItem.canvasDetails.size;

    const aspect = selectedSize ? (selectedSize.width / selectedSize.height) : 1;

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (crop) {
            return;
        }

        const { width, height } = e.currentTarget;

        const centerAspectCrop = getCenterAspectCrop(width, height, aspect);
        setCrop(centerAspectCrop);

        const pixelCrop = convertToPixelCrop(centerAspectCrop, width, height);
        setCompletedCrop(pixelCrop);
    }

    // called when user is done dragging/cropping and let go of mouse hold
    function onCropComplete(crop: PixelCrop) {
        setCompletedCrop(crop);

        if (typeof ref !== 'function' && ref?.current) {
            const xScale = ref.current.naturalWidth / ref.current.width;
            const yScale = ref.current.naturalHeight / ref.current.height;

            const cropDetails: CanvasCropDetails = {
                x: Math.floor(crop.x * xScale),
                y: Math.floor(crop.y * yScale),
                width: Math.floor(crop.width * xScale),
                height: Math.floor(crop.height * yScale),
            };

            setCropDetails(cropDetails);
        }
    }

    if (!selectedImageSrc) {
        return null;
    }

    return (
        <div className="mb-6">
            <p className="mb-2 font-semibold text-lg text-gray-900">Croping Photo</p>

            <div>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={onCropComplete}
                    aspect={aspect}
                    keepSelection={true}
                >
                    <img
                        alt="crop"
                        ref={ref}
                        src={selectedImageSrc}
                        onLoad={onImageLoad}
                        crossOrigin='anonymous'
                    />
                </ReactCrop>
            </div>

            <p className="mb-2 text-sm text-gray-700">Drag on image area to select.</p>
        </div>
    );
});
