import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { usersApi } from "../../users/api/users.api";
import { QUERY_KEYS } from "../../../common/query-keys";

function useGetPremiumArtistMembers() {
    const { data, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.USERS],
        queryFn: usersApi.getUsers,
        onError: () => {
            toast.error(`Couldn't get premium artist members`);
        },
    });

    return {
        users: data?.data,
        isLoading: isLoading,
    };
}

export function PremiumArtistMemberList() {
    const { users, isLoading } = useGetPremiumArtistMembers();
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Premium Artist Members</h2>

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="whitespace-nowrap px-2 py-2.5 text-left text-sm font-semibold text-gray-900">
                                            Firstname
                                        </th>
                                        <th scope="col" className="whitespace-nowrap px-2 py-2.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th>
                                        <th scope="col" className="whitespace-nowrap px-2 py-2.5 text-left text-sm font-semibold text-gray-900">
                                            Discount (%)
                                        </th>
                                        <th scope="col" className="whitespace-nowrap px-2 py-2.5 text-left text-sm font-semibold text-gray-900 text-right">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200">
                                    {users?.map((user) => (
                                        <tr key={user.id}>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{user.firstName}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{user.email}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{user.discountPercentage}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right text-sm font-medium">
                                                <Link
                                                    to={`${user.id}/edit`}
                                                    className="text-blue-500 hover:text-blue-700 hover:underline"
                                                >
                                                    Edit
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
