import { CanvasCropDetails } from "../stores/canvas/canvas-order.store";
import { canvasPreview } from "./canvas-preview";

export function dataUriToBlob(dataUri: string): Blob {
    const binary = atob(dataUri.split(',')[1]);
    const bytesArray = [];

    for (var i = 0; i < binary.length; i++) {
        bytesArray.push(binary.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(bytesArray)], { type: 'image/jpeg' });

    return blob;
}

export function getCroppedImageDataUrl(imgSrc: string, cropDetails: CanvasCropDetails) {
    return new Promise<string>((resolve) => {
        const img = document.createElement('img');
        const canvas = document.createElement('canvas');
    
        img.crossOrigin = 'anonymous';
        img.src = imgSrc;
    
        img.onload = () => {
            canvasPreview(img, canvas, cropDetails);
    
            const croppedImageDataUrl = canvas.toDataURL("image/jpeg");
    
            img.remove();
            canvas.remove();

            resolve(croppedImageDataUrl);
        }
    });
}
