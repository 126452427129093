import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { config } from './config';
import { baseApiInterceptors } from './api/base-api.interceptor';
import { LOCAL_STORAGE_KEYS } from './constants/local-storage.constant';

Amplify.configure({
    Auth: {
        region: config.aws.cognito.REGION,
        userPoolId: config.aws.cognito.USER_POOL_ID,
        userPoolWebClientId: config.aws.cognito.USER_POOL_APP_CLIENT_ID,
    },
});

if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_DATA)) {
    baseApiInterceptors.addAuthHeaderInterceptor();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
