import create from "zustand";
import { Crop, PixelCrop } from 'react-image-crop';
import {
    FinishingItem,
    GalleryItem,
    HardwareItem,
    SinglePrintBorder,
    SinglePrintSize,
    SinglePrintSizeTag,
    SinglePrintWrap,
} from "../../modules/canvas/models";
import { SinglePrintDetailsDto } from "../../modules/canvas/dto/canvas.dto";

export enum DeliveryMethod {
    SHIPPING = "SHIPPING",
    PICKUP = "PICKUP",
}

export interface CanvasDetails {
    selectedSizeTag: SinglePrintSizeTag;
    size: SinglePrintSize | null;
    wrap: SinglePrintWrap | null;
    border: SinglePrintBorder | null;
    borderColour: string;
    hardwareItem: HardwareItem | null;
    colourFinishing: FinishingItem | null;
    shouldLaminate: boolean;
    photoRetouchings: string[];
    retouchingDescription: string;
    selectedGalleryItem: GalleryItem | null;
    isGalleryItem: boolean;
}

export interface CanvasCropDetails {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface CanvasOrderItem {
    originalFileUrl?: string;
    croppedFileUrl?: string;
    cropDetails: CanvasCropDetails;
    canvasDetails: CanvasDetails;
    quantity: number;

    isGalleryItem?: boolean;
    galleryItemId?: number;

    imgSrc: string;
    crop: Crop | undefined;
    completedCrop: PixelCrop | undefined;
}

export interface ShippingInfo {
    firstName: string;
    lastName: string;
    address: string;
    apartment: string;
    city: string;
    country: string;
    state: string;
    postalCode: string;
    phoneNumber: string;
    email: string;
}

export interface CanvasOrderState {
    orderItems: CanvasOrderItem[];
    selectedOrderItemIndex: number;
    deliveryMethod: DeliveryMethod;
    shippingInfo: ShippingInfo;
    shippingPrice: number;
    setSelectedOrderItemIndex: (index: number) => void;
    addNewItem: () => void;
    removeItem: (index: number) => void;
    resetOrderState: () => void;
    setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
    setShippingInfo: (shippingInfo: ShippingInfo) => void;
    getShippingInfo: () => ShippingInfo;
    setShippingPrice: (shippingPrice: number) => void;

    setSelectedSizeTag: (sizeTag: SinglePrintSizeTag) => void;
    setSize: (size: SinglePrintSize | null) => void;
    setWrap: (wrap: SinglePrintWrap | null) => void;
    setBorder: (border: SinglePrintBorder | null) => void;
    setBorderColour: (colourHex: string) => void;
    setHardwareItem: (hardwareItem: HardwareItem | null) => void;
    setColourFinishing: (finishingItem: FinishingItem | null) => void;
    setShouldLaminate: (shouldLaminate: boolean) => void;
    setPhotoRetouchings: (photoRetouchings: string[]) => void;
    setRetouchingDescription: (retouchingDescription: string) => void;
    setCropDetails: (cropDetails: CanvasCropDetails) => void;
    setQuantity: (quantity: number, index: number) => void;
    setInitialSelections: (singlePrintDetails: SinglePrintDetailsDto) => void;

    setImgSrc: (imgSrc: string, galleryItemId?: number) => void;
    setCrop: (crop: Crop | undefined) => void;
    setCompletedCrop: (crop: PixelCrop | undefined) => void;
}

export const useCanvasOrderStore = create<CanvasOrderState>((set, get) => ({
    orderItems: [
        createNewOrderItem(),
    ],
    selectedOrderItemIndex: 0,
    deliveryMethod: DeliveryMethod.SHIPPING,
    shippingInfo: {
        firstName: '',
        lastName: '',
        address: '',
        apartment: '',
        city: '',
        country: 'US',
        state: '',
        postalCode: '',
        phoneNumber: '',
        email: '',
    },
    shippingPrice: 0,
    setSelectedOrderItemIndex: (index) => {
        set(() => ({
            selectedOrderItemIndex: index,
        }));
    },
    addNewItem: () => {
        const state = get();

        const newItem = createNewOrderItem();
        const orderItems = [
            ...state.orderItems,
            newItem,
        ];

        set(() => ({
            orderItems: orderItems,
        }));
    },
    removeItem: (index) => {
        const state = get();

        const orderItems = [...state.orderItems];
        orderItems.splice(index, 1);

        if (orderItems.length === 0) {
            const newItem = createNewOrderItem();
            orderItems.push(newItem);
        }

        set(() => ({
            orderItems: orderItems,
            selectedOrderItemIndex: 0,
        }));
    },
    resetOrderState: () => {
        set(() => ({
            orderItems: [
                createNewOrderItem(),
            ],
            selectedOrderItemIndex: 0,
        }));
    },
    setDeliveryMethod: (deliveryMethod) => {
        set(() => ({
            deliveryMethod: deliveryMethod,
        }));
    },
    setShippingInfo: (shippingInfo) => {
        set(() => ({
            shippingInfo: shippingInfo,
        }));
    },
    getShippingInfo: () => {
        const state = get();

        return state.shippingInfo;
    },
    setShippingPrice: (shippingPrice) => {
        set(() => ({
            shippingPrice: shippingPrice,
        }));
    },

    setSelectedSizeTag: (sizeTag) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.selectedSizeTag = sizeTag;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setSize: (size) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.size = size;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setWrap: (wrap) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.wrap = wrap;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setBorder: (border) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.border = border;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setBorderColour: (colourHex) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.borderColour = colourHex;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setHardwareItem: (hardwareItem) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.hardwareItem = hardwareItem;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setColourFinishing: (finishingItem) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.colourFinishing = finishingItem;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setShouldLaminate: (shouldLaminate) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.shouldLaminate = shouldLaminate;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setPhotoRetouchings: (photoRetouchings) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.photoRetouchings = photoRetouchings;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setRetouchingDescription: (retouchingDescription) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.canvasDetails.retouchingDescription = retouchingDescription;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setCropDetails: (cropDetails) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.cropDetails = cropDetails;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setQuantity: (quantity, index) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[index];
        orderItem.quantity = quantity;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setInitialSelections: (singlePrintDetails) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];

        if (!orderItem.canvasDetails.size && singlePrintDetails.sizes[0]) {
            orderItem.canvasDetails.size = singlePrintDetails.sizes[0];
        }

        if (!orderItem.canvasDetails.wrap && singlePrintDetails.wraps[0]) {
            orderItem.canvasDetails.wrap = singlePrintDetails.wraps[0];
        }

        if (!orderItem.canvasDetails.colourFinishing && singlePrintDetails.colourFinishingItems[0]) {
            orderItem.canvasDetails.colourFinishing = singlePrintDetails.colourFinishingItems[0];
        }

        set(() => ({
            orderItems: orderItems,
        }));
    },

    setImgSrc: (imgSrc, galleryItemId) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.imgSrc = imgSrc;

        if (galleryItemId) {
            orderItem.isGalleryItem = true;
            orderItem.galleryItemId = galleryItemId;
        }

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setCrop: (crop) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.crop = crop;

        set(() => ({
            orderItems: orderItems,
        }));
    },
    setCompletedCrop: (crop) => {
        const state = get();

        const orderItems = [...state.orderItems];
        const orderItem = orderItems[state.selectedOrderItemIndex];
        orderItem.completedCrop = crop;

        set(() => ({
            orderItems: orderItems,
        }));
    },
}));

function createNewOrderItem(): CanvasOrderItem {
    return {
        quantity: 1,
        canvasDetails: {
            selectedSizeTag: SinglePrintSizeTag.Square,
            size: null,
            wrap: null,
            border: null,
            borderColour: '',
            hardwareItem: null,
            colourFinishing: null,
            shouldLaminate: false,
            photoRetouchings: [],
            retouchingDescription: '',
            selectedGalleryItem: null,
            isGalleryItem: false,
        },
        cropDetails: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        },
        imgSrc: '',
        crop: undefined,
        completedCrop: undefined,
    };
}
