import { centerCrop, makeAspectCrop } from 'react-image-crop';

// default crop width of image for center aspect crop
const IMAGE_CROP_WIDTH_PERCENTAGE = 100;

export function getCenterAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number, cropWidthPercentage: number = 100) {
    const aspectCrop = makeAspectCrop(
        {
            unit: '%',
            width: cropWidthPercentage || IMAGE_CROP_WIDTH_PERCENTAGE,
        },
        aspect,
        mediaWidth,
        mediaHeight
    );

    const crop = centerCrop(aspectCrop, mediaWidth, mediaHeight);

    return crop;
}
