import React, { useRef } from "react";
import { canvasPreview } from "../../../utils/canvas-preview";
import { useDebounceEffect } from "../../../utils/use-debounce-effect";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";

interface PreviewCanvasProps {
    imgCropRef: React.RefObject<HTMLImageElement>;
}

export function PreviewCanvas({ imgCropRef }: PreviewCanvasProps) {
    const ref = useRef<HTMLCanvasElement>(null);

    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const completedCrop = selectedOrderItem.completedCrop;

    // set canvas preview after 100ms debounce time
    useDebounceEffect(setCanvasPreview, 100, [completedCrop]);

    function setCanvasPreview() {
        if (completedCrop && imgCropRef.current && ref.current) {
            canvasPreview(imgCropRef.current, ref.current, completedCrop);
        }
    }

    if (!completedCrop) {
        return null;
    }

    return (
        <div className="shadow-2xl drop-shadow-2xl">
            <canvas
                ref={ref}
                style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    maxWidth: '600px',
                    maxHeight: '600px'
                }}
            />
        </div>
    );
}
