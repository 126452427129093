import { Link } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../constants/local-storage.constant";

export function PostCheckoutPage() {
  const latestOrderId = localStorage.getItem(LOCAL_STORAGE_KEYS.LATEST_ORDER_ID);

  return (
    <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <Link to="/" className="inline-flex">
            <span className="sr-only">American Canvas Prints</span>
            <img className="h-32 w-auto" src="/acp-logo.jpeg" alt="" />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-green-600">Order Placed</p>
            <h1 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Checkout Success</h1>
            <p className="mt-2 text-base text-gray-500">Your canvas order has been placed.</p>
            {latestOrderId ? <p className="mt-2 text-base text-gray-500">Order ID: {latestOrderId}</p> : null}
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-yellow-600 hover:text-yellow-500">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a href="mailto:info@americancanvasprints.com" className="text-sm font-medium text-gray-500 hover:text-gray-600">Contact Support</a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true"></span>
          <a href="https://facebook.com/profile.php?id=100086597869172" className="text-sm font-medium text-gray-500 hover:text-gray-600">Facebook</a>
          <span className="inline-block border-l border-gray-300" aria-hidden="true"></span>
          <a href="https://www.instagram.com/americancanvasprints" className="text-sm font-medium text-gray-500 hover:text-gray-600">Instagram</a>
        </nav>
      </footer>
    </div>
  );
}
