import { useGetCanvasPhotoRetouchingOptions } from "../../../hooks/use-get-canvas-product-details.hook";
import { useCanvasOrderStore } from "../../../stores/canvas/canvas-order.store";
import { calculateLaminatePrice } from "../../../utils/order-price.util";
import { LAMINATE_PRICE_PER_SQFT } from "../../../constants/data.constant";

export function OtherOptions() {
    const { photoRetouchingOptions } = useGetCanvasPhotoRetouchingOptions();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setShouldLaminate = useCanvasOrderStore((state) => state.setShouldLaminate);
    const setPhotoRetouchings = useCanvasOrderStore((state) => state.setPhotoRetouchings);
    const setRetouchingDescription = useCanvasOrderStore((state) => state.setRetouchingDescription);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const shouldLaminate = selectedOrderItem.canvasDetails.shouldLaminate;
    const photoRetouchings = selectedOrderItem.canvasDetails.photoRetouchings;
    const retouchingDescription = selectedOrderItem.canvasDetails.retouchingDescription;

    function onPhotoRetouchingChange(e: React.ChangeEvent<HTMLInputElement>) {
        const checked = e.target.checked;
        const retouchingOption = e.target.value;

        if (!checked) {
            const newRetouchings = photoRetouchings.filter((p) => p !== retouchingOption);
            setPhotoRetouchings(newRetouchings);
            return;
        }

        if (checked && !photoRetouchings.includes(retouchingOption)) {
            const newRetouchings = [...photoRetouchings, retouchingOption];
            setPhotoRetouchings(newRetouchings);
        }
    }

    function onShouldLaminateChange(e: React.ChangeEvent<HTMLInputElement>) {
        const shouldLaminateValue = e.target.value;

        if (shouldLaminateValue === "yes") {
            setShouldLaminate(true);
        } else if (shouldLaminateValue === "no") {
            setShouldLaminate(false);
        }
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Optional Photo Edits</h4>

            <div className="mb-4">
                <div className="my-1 text-lg font-medium text-gray-800">Laminating options:</div>

                <div className="space-y-2.5">
                    <div className="flex items-center">
                        <input
                            id="laminate_yes"
                            type="radio"
                            name="laminate"
                            value="yes"
                            checked={shouldLaminate}
                            onChange={onShouldLaminateChange}
                            className="h-5 w-5 border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label className="ml-3 block text-base font-medium text-gray-700" htmlFor="laminate_yes">
                            Yes (Lamination Price: ${selectedOrderItem.canvasDetails.size ? calculateLaminatePrice(selectedOrderItem.canvasDetails.size, LAMINATE_PRICE_PER_SQFT) : 0})
                        </label>
                    </div>
                    <div className="flex items-center">
                        <input
                            id="laminate_no"
                            type="radio"
                            name="laminate"
                            value="no"
                            checked={!shouldLaminate}
                            onChange={onShouldLaminateChange}
                            className="h-5 w-5 border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label className="ml-3 block text-base font-medium text-gray-700" htmlFor="laminate_no">No</label>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="my-1 text-lg font-medium text-gray-800">Minor photo retouching:</div>

                <div className="space-y-2.5">
                    {photoRetouchingOptions?.map((retouchingOption) => (
                        <div key={retouchingOption} className="flex items-center">
                            <input
                                id={retouchingOption}
                                className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                type="checkbox"
                                checked={photoRetouchings.includes(retouchingOption)}
                                value={retouchingOption}
                                onChange={onPhotoRetouchingChange}
                            />
                            <label className="ml-3 block text-base font-medium text-gray-700" htmlFor={retouchingOption}>{retouchingOption}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <div className="mb-1 text-lg font-medium text-gray-800">Major photo retouching:</div>

                <div>
                    <label className="mb-2 block text-md text-gray-600" htmlFor="input_retouching">Requirements for retouching</label>
                    <textarea
                        id="input_retouching"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        rows={4}
                        value={retouchingDescription}
                        onChange={(e) => setRetouchingDescription(e.target.value)}
                    ></textarea>
                </div>
            </div>
        </div>
    );
}
