import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { singlePrintSizesApi } from "../api/single-print-sizes.api";
import { useLoaderStore } from "../../../stores/loader.store";
import { SINGLE_PRINT_SIZE_TAGS, SinglePrintSizeTag, SinglePrintSizeWrap } from "../../canvas/models/single-print-size.model";
import { UpdateSinglePrintSizeDto } from "../api/dto";
import { QUERY_KEYS } from "../../../common/query-keys";

export function EditSinglePrintSize() {
    const params = useParams();
    const sizeId = parseInt(params.id!);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [sizeTag, setSizeTag] = useState<SinglePrintSizeTag>(SinglePrintSizeTag.Square);
    const [sizeWraps, setSizeWraps] = useState<SinglePrintSizeWrap[]>([]);

    const queryClient = useQueryClient();
    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SINGLE_PRINT_SIZE_BY_ID, sizeId],
        queryFn: () => {
            return singlePrintSizesApi.getSinglePrintSizeById(sizeId);
        },
        enabled: !!sizeId,
        onSuccess: (res) => {
            const sizeDetails = res.data;

            setWidth(sizeDetails.width);
            setHeight(sizeDetails.height);
            setSizeTag(sizeDetails.tag);
            setSizeWraps(sizeDetails.sizeWraps);
        },
        onError: () => {
            toast.error(`Couldn't get size details`);
        },
    });
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    function onPriceChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const price = isNaN(e.target.valueAsNumber) ? '' as any : e.target.valueAsNumber;

        const newSizeWraps = [...sizeWraps];
        newSizeWraps[index].price = price;
        setSizeWraps(newSizeWraps);
    }

    function onWholesalePriceChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const price = isNaN(e.target.valueAsNumber) ? '' as any : e.target.valueAsNumber;

        const newSizeWraps = [...sizeWraps];
        newSizeWraps[index].wholesalePrice = price;
        setSizeWraps(newSizeWraps);
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        updateSinglePrintSize({
            width: width,
            height: height,
            tag: sizeTag,
            sizeWraps: sizeWraps,
        });
    }

    async function updateSinglePrintSize(updateData: UpdateSinglePrintSizeDto) {
        setIsLoading(true);

        try {
            const res = await singlePrintSizesApi.updateSinglePrintSize(sizeId, updateData);

            if (res.success) {
                toast.success(`Successfully updated size details`);
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_SIZE_BY_ID, sizeId] });
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SINGLE_PRINT_SIZES] });
                return;
            }

            toast.error(`Couldn't update size details`);
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't update size details`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Edit size details</h2>

            <form className="mt-8 space-y-2" onSubmit={onSubmit}>
                <div className="grid md:grid-cols-3 gap-3">
                  <div className="grid grid-cols-2 gap-x-3">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="width">Width (in)</label>
                        <input
                            id="width"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            type="number"
                            value={width}
                            onChange={(e) => setWidth(parseInt(e.target.value) || '' as any)}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="height">Height (in)</label>
                        <input
                            id="height"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            type="number"
                            value={height}
                            onChange={(e) => setHeight(parseInt(e.target.value) || '' as any)}
                        />
                    </div>
                  </div>

                  <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="tag">Photo Orientation</label>
                      <select
                          id="tag"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          name="tag"
                          value={sizeTag}
                          onChange={(e) => setSizeTag(e.target.value as SinglePrintSizeTag)}
                      >
                          {SINGLE_PRINT_SIZE_TAGS.map((tag) => (
                              <option key={tag} value={tag}>{tag}</option>
                          ))}
                      </select>
                  </div>
                </div>

                <div>
                    <h4 className="mt-4 font-semibold text-lg text-gray-900">Prices</h4>
                </div>

                <div className="grid sm:grid-cols-2 sm:gap-x-3 gap-y-6">
                    {sizeWraps.map((sizeWrap, index) => (
                        <div key={index}>
                            <div>
                                <img
                                    src={sizeWrap.wrap?.imageUrl}
                                    className="mb-1 w-auto h-32"
                                />
                            </div>

                            <div className="mt-2.5">
                                <label
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                    htmlFor={`wrap-${sizeWrap.wrapId}-price`}
                                >
                                    {sizeWrap.wrap?.name} price ($)
                                </label>

                                <div className="mt-1 w-24">
                                    <input
                                        id={`wrap-${sizeWrap.wrapId}-price`}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        value={sizeWrap.price}
                                        onChange={(e) => onPriceChange(e, index)}
                                    />
                                </div>
                            </div>

                            <div className="mt-2.5">
                                <label
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                    htmlFor={`wrap-${sizeWrap.wrapId}-wholesale-price`}
                                >
                                    {sizeWrap.wrap?.name} wholesale/PAM price ($)
                                </label>

                                <div className="mt-1 w-24">
                                    <input
                                        id={`wrap-${sizeWrap.wrapId}-wholesale-price`}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        value={sizeWrap.wholesalePrice}
                                        onChange={(e) => onWholesalePriceChange(e, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div>
                    <button
                        className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
