import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import {
    HomePage,
    NotFoundPage,
    PostCheckoutPage,
    OurGalleryPage,
    LoginPage,
    CanvasOrderPage,
    SignUpPage,
    ChangeInitialPasswordPage,
    ForgotPasswordPage,
    ResetPasswordPage,
} from './pages';
import { FullScreenLoader } from './shared/components';
import {
    AdminLayout,
    EditHardwareItem,
    EditOtherPrices,
    EditPremiumArtistMember,
    EditSinglePrintBorder,
    EditSinglePrintSize,
    EditSinglePrintWrap,
    HardwareItemList,
    PremiumArtistMemberList,
    SinglePrintBorderList,
    SinglePrintSizeList,
    SinglePrintWrapList,
} from './modules/admin/components';
import { useAuthStore } from './stores/auth.store';
import { currentUserApi } from './modules/users/api/current-user.api';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

function App() {
    const setIsLoadingAuth = useAuthStore((state) => state.setIsLoadingAuth);
    const setUser = useAuthStore((state) => state.setUser);

    useEffect(() => {
        async function checkAuth() {
            try {
                const userSession = await Auth.currentSession();

                if (userSession) {
                    const res = await currentUserApi.getProfile();

                    if (res.success) {
                        setUser(res.data);
                    }
                }
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoadingAuth(false);
            }
        }

        checkAuth();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/sign-up' element={<SignUpPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/canvas/order' element={<CanvasOrderPage />} />
                <Route path='/post-checkout' element={<PostCheckoutPage />} />
                <Route path='/our-gallery' element={<OurGalleryPage />} />
                <Route path='/change-initial-password' element={<ChangeInitialPasswordPage />} />
                {/* <Route path='/forgot-password' element={<ForgotPasswordPage />} /> */}
                {/* <Route path='/password-reset' element={<ResetPasswordPage />} /> */}

                <Route path='admin' element={<AdminLayout />}>
                    <Route index element={<Navigate to="premium-artist-members" />} />

                    <Route path='premium-artist-members' element={<PremiumArtistMemberList />} />
                    <Route path='premium-artist-members/:id/edit' element={<EditPremiumArtistMember />} />

                    <Route path='single-print-sizes' element={<SinglePrintSizeList />} />
                    <Route path='single-print-sizes/:id/edit' element={<EditSinglePrintSize />} />

                    <Route path='single-print-wraps' element={<SinglePrintWrapList />} />
                    <Route path='single-print-wraps/:id/edit' element={<EditSinglePrintWrap />} />

                    <Route path='single-print-borders' element={<SinglePrintBorderList />} />
                    <Route path='single-print-borders/:id/edit' element={<EditSinglePrintBorder />} />

                    <Route path='hardware-items' element={<HardwareItemList />} />
                    <Route path='hardware-items/:id/edit' element={<EditHardwareItem />} />

                    <Route path='other-prices' element={<EditOtherPrices />} />
                </Route>

                {/* <Route path='*' element={<NotFoundPage />} /> */}
                <Route path='*' element={<Navigate to="/" />} />
            </Routes>

            <ToastContainer
                autoClose={3000}
            />

            <FullScreenLoader />
        </QueryClientProvider>
    );
}

export default App;
