export const QUERY_KEYS = {
    SINGLE_PRINT_DETAILS: 'single-print-details',
    SINGLE_PRINT_SIZES: 'single-print-sizes',
    SINGLE_PRINT_SIZE_BY_ID: 'single-print-size-by-id',
    SINGLE_PRINT_WRAPS: 'single-print-wraps',
    SINGLE_PRINT_WRAP_BY_ID: 'single-print-wrap-by-id',
    SINGLE_PRINT_BORDERS: 'single-print-borders',
    SINGLE_PRINT_BORDER_BY_ID: 'single-print-border-by-id',
    HARDWARE_ITEMS: 'hardware-items',
    HARDWARE_ITEM_BY_ID: 'hardware-item-by-id',
    USERS: 'users',
    GALLERY_ITEMS: 'gallery-items',
    OTHER_PRICES: 'other-prices',
    USER_GALLERY_IMAGES: 'user-gallery-images',
} as const;
