import { baseApi } from "../../../api/base-api";
import { SinglePrintWrap } from "../../canvas/models/single-print-wrap.model";
import { UpdateSinglePrintWrapDto } from "./dto";

async function getSinglePrintWraps() {
    return await baseApi.get<SinglePrintWrap[]>('canvas/single-print-wraps');
}

async function getSinglePrintWrapById(wrapId: number) {
    return await baseApi.get<SinglePrintWrap>(`canvas/single-print-wraps/${wrapId}`);
}

async function updateSinglePrintWrap(wrapId: number, updateData: UpdateSinglePrintWrapDto) {
    return await baseApi.put(`canvas/single-print-wraps/${wrapId}`, updateData);
}

export const singlePrintWrapsApi = {
    getSinglePrintWraps: getSinglePrintWraps,
    getSinglePrintWrapById: getSinglePrintWrapById,
    updateSinglePrintWrap: updateSinglePrintWrap,
} as const;
