import { baseApi } from "../../../api/base-api";
import { User } from "../models/user.model";
import { RegisterUserDto } from "./dto";

async function getUsers() {
    return await baseApi.get<User[]>('users');
}

async function getUserById(userId: number) {
    return await baseApi.get<User>(`users/${userId}`);
}

async function registerUser(userData: RegisterUserDto) {
    return await baseApi.post("users/register", userData);
}

async function updateUserDiscountPercentage(userId: number, data: { discountPercentage: number }) {
    return await baseApi.put(`users/${userId}/discount-percentage`, data);
}

export const usersApi = {
    getUsers: getUsers,
    getUserById: getUserById,
    registerUser: registerUser,
    updateUserDiscountPercentage: updateUserDiscountPercentage,
} as const;
