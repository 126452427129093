import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { HexColorPicker, HexColorInput } from "react-colorful";

interface ColourPickerModalProps {
    show: boolean;
    colour: string;
    onClose: () => void;
    onColourChange: (colour: string) => void;
}

export function ColourPickerModal({ show, colour, onClose, onColourChange }: ColourPickerModalProps) {
    return (
        <div>
            <Transition appear show={show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/70" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-auto p-6 overflow-hidden bg-white text-left align-middle rounded-lg shadow-xl transform transition-all">
                                    <div className="flex items-center justify-end">
                                      <button
                                        className="mb-3 p-1 flex items-center justify-center rounded group bg-gray-100 hover:bg-gray-200"
                                        onClick={() => onClose()}
                                      >
                                        <span className="sr-only">Close Color Picker</span>
                                        <XMarkIcon className="h-6 w-6 flex text-gray-700 group-hover:text-gray-900" aria-hidden="true" />
                                      </button>
                                    </div>
                                    <div>
                                        <HexColorPicker color={colour} onChange={onColourChange} />
                                        <div className="mt-3 space-x-1 flex items-center justify-start color-input">
                                          <span>#</span>
                                          <HexColorInput color={colour} onChange={onColourChange} />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
