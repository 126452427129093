import React, { useMemo } from 'react';
import { convertToPixelCrop } from 'react-image-crop';
import { classNames } from '../../../utils/class-names.util';
import { SinglePrintSize } from '../models';
import { useCanvasOrderStore } from '../../../stores/canvas/canvas-order.store';
import { CANVAS_SIZE_TAGS } from '../../../constants/data.constant';
import { getCenterAspectCrop } from '../../../utils/get-center-aspect-crop';
import { useGetCanvasSizes } from '../../../hooks/use-get-canvas-product-details.hook';

interface SelectSizeProps {
    imgCropRef: React.RefObject<HTMLImageElement>;
}

export function SelectSize({ imgCropRef }: SelectSizeProps) {
    const { canvasSizes } = useGetCanvasSizes();
    const orderItems = useCanvasOrderStore((state) => state.orderItems);
    const selectedOrderItemIndex = useCanvasOrderStore((state) => state.selectedOrderItemIndex);
    const setSize = useCanvasOrderStore((state) => state.setSize);
    const setSelectedSizeTag = useCanvasOrderStore((state) => state.setSelectedSizeTag);
    const setCrop = useCanvasOrderStore((state) => state.setCrop);
    const setCompletedCrop = useCanvasOrderStore((state) => state.setCompletedCrop);

    const selectedOrderItem = orderItems[selectedOrderItemIndex];
    const selectedSizeTag = selectedOrderItem.canvasDetails.selectedSizeTag;
    const selectedSize = selectedOrderItem.canvasDetails.size;
    const selectedWrap = selectedOrderItem.canvasDetails.wrap;

    const canvasSizesForSelections = useMemo(() => {
        return canvasSizes?.filter((size) => size.tag === selectedSizeTag);
    }, [selectedSizeTag]);

    function handleSelectSize(size: SinglePrintSize) {
        setSize(size);

        if (imgCropRef.current) {
            const { width, height } = imgCropRef.current;

            const centerAspectCrop = getCenterAspectCrop(width, height, size.width / size.height);
            setCrop(centerAspectCrop);

            const pixelCrop = convertToPixelCrop(centerAspectCrop, width, height);
            setCompletedCrop(pixelCrop);
        }
    }

    function calculateCanvasPrice(size: SinglePrintSize): number {
        const canvasPrice = size.sizeWraps.find((sizeWrap) => sizeWrap.wrapId === selectedWrap?.id);

        return canvasPrice?.price || size.sizeWraps[0].price;
    }

    return (
        <div>
            <h4 className="mb-2 text-xl font-semibold">Choose Photo Orientation</h4>

            <div className="mb-4 grid grid-cols-4 gap-2">
                {CANVAS_SIZE_TAGS.map((sizeTag) => (
                    <button
                        type="button"
                        key={sizeTag}
                        onClick={() => setSelectedSizeTag(sizeTag)}
                        className={classNames(
                            'px-2 py-3 flex flex-col items-center justify-center rounded transition duration-150',
                            selectedSizeTag === sizeTag ? 'bg-yellow-500 text-white' : 'bg-blue-900/10 hover:bg-blue-900/20'
                        )}
                    >
                        <div className="text-lg">{sizeTag}</div>
                    </button>
                ))}
            </div>

            <h4 className="mt-2 mb-2 text-xl font-semibold">Select Canvas Size</h4>

            <div className="grid grid-cols-3 xl:grid-cols-4 gap-2">
                {canvasSizesForSelections?.map((size) => (
                    <button
                        type="button"
                        key={size.id}
                        onClick={() => handleSelectSize(size)}
                        className={classNames(
                            'px-3 py-3 leading-loose text-sm rounded transition duration-150',
                            selectedSize?.id === size.id ? 'bg-yellow-500 text-white' : 'bg-blue-900/10 text-gray-900 hover:bg-blue-900/20'
                        )}
                    >
                        <div className="mb-1 text-lg font-medium">{size.width}" x {size.height}"</div>
                        <div className="text-sm">${calculateCanvasPrice(size)}</div>
                    </button>
                ))}
            </div>
        </div>
    );
}
