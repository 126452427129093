import { baseApi } from "../../../api/base-api";
import { HardwareItem } from "../../canvas/models";
import { UpdateHardwareItemDto } from "./dto";

async function getHardwareItems() {
    return await baseApi.get<HardwareItem[]>('canvas/hardware-items');
}

async function getHardwareItemById(hardwareItemId: number) {
    return await baseApi.get<HardwareItem>(`canvas/hardware-items/${hardwareItemId}`);
}

async function updateHardwareItem(hardwareItemId: number, updateData: UpdateHardwareItemDto) {
    return await baseApi.put(`canvas/hardware-items/${hardwareItemId}`, updateData);
}

export const hardwareItemsApi = {
    getHardwareItems: getHardwareItems,
    getHardwareItemById: getHardwareItemById,
    updateHardwareItem: updateHardwareItem,
} as const;
