import { useEffect, useRef } from 'react';
import { useCanvasNavigationStore } from '../stores/navigation.store';
import { useGetSinglePrintDetails } from '../hooks/use-get-canvas-product-details.hook';
import { AppHeader, LeftSidebar } from '../shared/components';
import { useCanvasOrderStore } from '../stores/canvas/canvas-order.store';
import { useLoaderStore } from '../stores/loader.store';
import {
    ImageCrop,
    OrderSummary,
    OtherOptions,
    PreviewCanvas,
    SelectBorder,
    SelectFinishing,
    SelectHardware,
    SelectImage,
    SelectSize,
    SelectWrap,
    ShippingInfo,
} from '../modules/canvas/components';

export function CanvasOrderPage() {
    const { isLoading: isLoadingSinglePrintDetails } = useGetSinglePrintDetails();

    const setImgSrc = useCanvasOrderStore((state) => state.setImgSrc);
    const resetOrderState = useCanvasOrderStore((state) => state.resetOrderState);

    const currentTab = useCanvasNavigationStore((state) => state.currentTab);
    const selectedGalleryItem = useCanvasNavigationStore((state) => state.selectedGalleryItem);
    const setCurrentTab = useCanvasNavigationStore((state) => state.setCurrentTab);
    const setSelectedGalleryItem = useCanvasNavigationStore((state) => state.setSelectedGalleryItem);

    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    const imgCropRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        return () => {
            setCurrentTab('image');
            resetOrderState();
        }
    }, []);

    useEffect(() => {
        setIsLoading(isLoadingSinglePrintDetails);
    }, [isLoadingSinglePrintDetails]);

    useEffect(() => {
        if (!selectedGalleryItem) {
            return;
        }

        setImgSrc(selectedGalleryItem.imageUrl);
        setSelectedGalleryItem(null);
    }, [selectedGalleryItem]);

    return (
        <div className="flex h-screen">
            <LeftSidebar />

            {/* Content area */}
            <div className="flex flex-1 flex-col overflow-hidden overflow-y-auto">
                <AppHeader />

                {/* Main app layout */}
                <div className="relative z-0 flex-col lg:flex lg:flex-row flex-1 lg:overflow-hidden">
                    <main className="relative z-0 w-full lg:w-6/12 p-5 lg:grid lg:flex-1 overflow-y-auto focus:outline-none">
                        {/* Primary column */}
                        <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last">
                            <div>
                                {currentTab !== 'checkout' && (
                                    <ImageCrop ref={imgCropRef} />
                                )}

                                {currentTab === 'image' && <SelectImage />}

                                {currentTab === 'size' && <SelectSize imgCropRef={imgCropRef} />}

                                {currentTab === 'wrap' && <SelectWrap />}

                                {currentTab === 'border' && <SelectBorder />}

                                {currentTab === 'hardware' && <SelectHardware />}

                                {currentTab === 'finishing' && <SelectFinishing />}

                                {currentTab === 'other' && <OtherOptions />}

                                {currentTab === 'checkout' && <ShippingInfo />}
                            </div>
                        </section>
                    </main>

                    {/* Secondary column (hidden on smaller screens) */}
                    {currentTab !== 'checkout' && (
                        <aside className="w-full lg:w-6/12 p-5 border-l border-gray-300 bg-white overflow-y-auto aside-bg">
                            <div className="flex justify-center">
                                <PreviewCanvas imgCropRef={imgCropRef} />
                            </div>
                        </aside>
                    )}

                    {currentTab === 'checkout' && (
                        <aside className="w-full lg:w-6/12 p-5 border-l border-gray-300 bg-white overflow-y-auto">
                            <OrderSummary />
                        </aside>
                    )}
                </div>
            </div>
        </div>
    );
}
