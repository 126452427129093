import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactImageGalleryItem } from "react-image-gallery";
import { EyeIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { useGetGalleryItems } from "../hooks/use-get-gallery-items.hook";
import { GalleryItem } from "../modules/canvas/models";
import { Header, GalleryModal } from "../shared/components";
import { useCanvasNavigationStore } from "../stores/navigation.store";

export function OurGalleryPage() {
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [previewGalleryItemImages, setPreviewGalleryItemImages] = useState<ReactImageGalleryItem[]>([]);
    const { galleryItems } = useGetGalleryItems();
    const setSelectedGalleryItem = useCanvasNavigationStore((state) => state.setSelectedGalleryItem);
    const navigate = useNavigate();

    function printGalleryItem(galleryItem: GalleryItem) {
        // TODO: set default size in store for selected gllery item
        setSelectedGalleryItem(galleryItem);

        // let defaultSizeForGalleryItem = canvasProductDetails.canvasSizes.find((canvasSize) => canvasSize.id === selectedGalleryItem.defaultSizeId);

        // if (defaultSizeForGalleryItem) {
        //     setSize(defaultSizeForGalleryItem);
        // }

        navigate('/canvas/order');

        // navigate({
        //     pathname: '/canvas/order',
        //     search: createSearchParams({
        //         galleryItemId: galleryItem.id.toString(),
        //     }).toString(),
        // });
    }

    function previewGalleryItem(galleryItem: GalleryItem) {
        const galleryItems: ReactImageGalleryItem[] = [];
        
        galleryItem.previewImageUrls.forEach((imageUrl) => {
            galleryItems.push({
                original: imageUrl,
                thumbnail: imageUrl,
            });
        });

        setShowGalleryModal(true);
        setPreviewGalleryItemImages(galleryItems);
    }

    return (
        <div>
            <Header />

            <div className="py-28 text-center gallery-hero bg-no-repeat bg-center bg-cover">
              <h1 className="text-4xl font-semibold tracking-tight text-gray-900">New Arrivals | Order now in time for Christmas 🎁</h1>
              <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">Get your own canvas print of our latest gallery this December</p>
            </div>
            
            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-xl font-semibold text-gray-900">Latest items</h2>

                <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {galleryItems?.map((galleryItem) => (
                    <div key={galleryItem.id}>
                      <div className="relative">
                        <div className="relative h-72 w-full overflow-hidden rounded-lg">
                          <img
                            className="h-full w-full object-cover object-center"
                            src={galleryItem.thumbnailUrl}
                            alt={galleryItem.name}
                            crossOrigin='anonymous'
                          />
                        </div>
                        <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-start overflow-hidden rounded-lg p-4">
                          <div aria-hidden="true" className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"></div>
                          <p className="relative text-lg font-semibold text-white">{galleryItem.name}</p>
                        </div>
                      </div>
                      <div className="mt-3 space-x-3 flex items-center justify-between">
                        <button
                            type="button"
                            className="py-2 px-8 space-x-1 relative flex flex-1 items-center justify-center rounded-md border border-transparent text-sm font-medium text-gray-900 bg-gray-100 hover:bg-gray-200"
                            onClick={() => printGalleryItem(galleryItem)}
                        >
                            <PrinterIcon className="h-4 w-4 text-gray-900" aria-hidden="true" />
                            <span>Print</span>
                        </button>
                        <button
                            type="button"
                            className="py-2 px-8 space-x-1 relative flex flex-1 items-center justify-center rounded-md border border-transparent text-sm font-medium text-gray-900 bg-gray-100 hover:bg-gray-200"
                            onClick={() => previewGalleryItem(galleryItem)}
                        >
                            <EyeIcon className="h-4 w-4 text-gray-900" aria-hidden="true" />
                            <span>Preview</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <GalleryModal
                show={showGalleryModal && previewGalleryItemImages?.length > 0}
                items={previewGalleryItemImages}
                onClose={() => {
                    setShowGalleryModal(false);
                    setPreviewGalleryItemImages([]);
                }}
            />
        </div>
    );
}
