import { baseApi } from "../../../api/base-api";
import { User } from "../models/user.model";
import { UserGalleryImage } from "../models/user-gallery-image.model";
import { CreateUserGalleryImageDto, UpdateUserGalleryImageDto } from "./dto";

async function getProfile() {
    return await baseApi.get<User>(`users/me/profile`);
}

async function getUserGalleryImages() {
    return await baseApi.get<UserGalleryImage[]>("users/me/gallery-images");
}

async function createUserGalleryImage(createUserGalleryImageDto: CreateUserGalleryImageDto) {
    return await baseApi.post("users/me/gallery-images", createUserGalleryImageDto);
}

async function updateUserGalleryImage(userGalleryImageId: number, updateUserGalleryImageDto: UpdateUserGalleryImageDto) {
    return await baseApi.put(`users/me/gallery-images/${userGalleryImageId}`, updateUserGalleryImageDto);
}

async function deleteUserGalleryImage(userGalleryImageId: number) {
    return await baseApi.delete(`users/me/gallery-images/${userGalleryImageId}`);
}

export const currentUserApi = {
    getProfile: getProfile,
    getUserGalleryImages: getUserGalleryImages,
    createUserGalleryImage: createUserGalleryImage,
    updateUserGalleryImage: updateUserGalleryImage,
    deleteUserGalleryImage: deleteUserGalleryImage,
} as const;
