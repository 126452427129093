import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";

interface GalleryModalProps {
    show: boolean;
    items: ReactImageGalleryItem[];
    onClose: () => void;
}

export function GalleryModal({ show, items, onClose }: GalleryModalProps) {
    return (
        <div>
            <Transition appear show={show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/70" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-auto p-6 transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="top-[30px] right-[30px] absolute flex items-center justify-end z-10">
                                      <button
                                        className="mb-3 p-1 flex items-center justify-center rounded group bg-black/40 hover:bg-white"
                                        onClick={() => onClose()}
                                      >
                                        <span className="sr-only">Close Preview</span>
                                        <XMarkIcon className="h-7 w-7 flex text-white group-hover:text-black" aria-hidden="true" />
                                      </button>
                                    </div>
                                    <ImageGallery items={items} lazyLoad={true} showBullets={true} showThumbnails={false} showFullscreenButton={false} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
