import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { QUERY_KEYS } from "../../../common/query-keys";
import { usersApi } from "../../users/api/users.api";
import { useLoaderStore } from "../../../stores/loader.store";
import { User } from "../../users/models/user.model";

const MIN_DISCOUNT_PERCENTAGE = 0;
const MAX_DISCOUNT_PERCENTAGE = 50;

export function EditPremiumArtistMember() {
    const params = useParams();
    const userId = parseInt(params.id!);
    const [userDetails, setUserDetails] = useState<User>();
    const [discountPercentage, setDiscountPercentage] = useState<number>(0);

    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.USERS, userId],
        queryFn: () => {
            return usersApi.getUserById(userId);
        },
        enabled: !!userId,
        onSuccess: (res) => {
            const userDetails = res.data;

            setUserDetails(userDetails);
            setDiscountPercentage(userDetails.discountPercentage ?? 0);
        },
        onError: () => {
            toast.error(`Couldn't get user details`);
        },
    });
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    function onDiscountPercentageChange(e: React.ChangeEvent<HTMLInputElement>) {
        const percentage = isNaN(e.target.valueAsNumber) ? '' as any : e.target.valueAsNumber;
        setDiscountPercentage(percentage);
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (discountPercentage < MIN_DISCOUNT_PERCENTAGE || discountPercentage > MAX_DISCOUNT_PERCENTAGE) {
            toast.error(`Discount percentage should be between ${MIN_DISCOUNT_PERCENTAGE} and ${MAX_DISCOUNT_PERCENTAGE}`);
            return;
        }

        updateUserDiscountPercentage();
    }

    async function updateUserDiscountPercentage() {
        setIsLoading(true);

        try {
            const res = await usersApi.updateUserDiscountPercentage(userId, { discountPercentage: discountPercentage });

            if (res.success) {
                toast.success(`Successfully updated discount percentage for user`);
                return;
            }

            toast.error(`Couldn't update discount percentage for user`);
        } catch (err) {
            console.log(err);
            toast.error(`Couldn't update discount percentage for user`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Edit premium artist member</h2>

            <div className="mt-8 space-y-2">
                <p className="text-gray-500">First Name: {userDetails?.firstName}</p>
                <p className="text-gray-500">Last Name: {userDetails?.lastName}</p>
                <p className="text-gray-500">Email: {userDetails?.email}</p>
                <p className="text-gray-500">Phone Number: {userDetails?.phoneNumber}</p>
                <p className="text-gray-500">Address: {userDetails?.address}</p>
                <p className="text-gray-500">Business Name: {userDetails?.businessName}</p>
            </div>

            <form className="mt-8 space-y-2" onSubmit={onSubmit}>
                <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="discountPercentage">Discount Percentage</label>
                    <input
                        id="discountPercentage"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="number"
                        min="0"
                        step="0.01"
                        value={discountPercentage}
                        onChange={onDiscountPercentageChange}
                    />
                </div>

                <div>
                    <button
                        className="mt-4 px-5 py-2 flex justify-center rounded-md text-sm font-semibold leading-6 text-white shadow-sm bg-yellow-500 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
