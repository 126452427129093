import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { useLoaderStore } from "../../../stores/loader.store";
import { hardwareItemsApi } from "../api/hardware-items.api";
import { QUERY_KEYS } from "../../../common/query-keys";

function useGetHardwareItems() {
    const { data, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.HARDWARE_ITEMS],
        queryFn: hardwareItemsApi.getHardwareItems,
        onError: () => {
            toast.error(`Couldn't get hardware item list`);
        },
    });

    return {
        hardwareItems: data?.data,
        isLoading: isLoading,
    };
}

export function HardwareItemList() {
    const { hardwareItems, isLoading } = useGetHardwareItems();
    const setIsLoading = useLoaderStore((state) => state.setIsLoading);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <div>
            <h2 className="font-semibold text-xl text-gray-900">Hardware items</h2>

            <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-5">
                {hardwareItems?.map((hardwareItem) => (
                    <div key={hardwareItem.id}>
                        <img className="w-auto h-32" src={hardwareItem.imageUrl} />

                        <p className="mt-1 block font-medium leading-6 text-gray-900">{hardwareItem.name}</p>

                        <p className="block text-sm font-medium leading-6 text-gray-600">Price: {hardwareItem.price}</p>

                        <Link
                            to={`${hardwareItem.id}/edit`}
                            className="text-blue-500 hover:text-blue-700 hover:underline"
                        >
                            Edit
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}
