import { Link } from "react-router-dom";
import { Header } from "../shared/components";

export function HomePage() {
    return (
        <div className="bg-white">
            <Header />

            <main>
                <div
                    className="bg-no-repeat bg-cover bg-[50%]"
                    style={{
                        height: "calc(100vh - 80px)",
                        backgroundImage: "url('assets/images/hero-background.jpg')"
                    }}
                >
                    <div className="h-full md:max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 flex items-center">
                        <div>
                            <h1 className="sm:max-w-2xl text-4xl font-semibold tracking-tight sm:text-5xl">
                                <span className="block text-black">Premium Custom Canvas Wall Art at an Affordable Price</span>
                            </h1>
                            <p className="mt-6 max-w-lg text-xl text-gray-900 sm:max-w-xl">Upload your own artwork or photography, select your options and our expert technicians print and handcraft. Your premium quality gallery wrapped canvas ready to hang.</p>
                            <p className="mt-6 max-w-lg text-xl text-gray-900 sm:max-w-xl">Satisfaction Guaranteed.</p>
                            <div className="mt-10 max-w-sm sm:flex sm:max-w-none">
                                <div>
                                    <Link to='/canvas/order' className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8">Order Your Custom Canvas</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative bg-white">
                    <div className="lg:absolute lg:inset-0">
                        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                            <img className="h-56 w-full object-cover lg:absolute lg:h-full" src="assets/images/custom-canvas.jpeg" loading="lazy" alt="" />
                        </div>
                    </div>
                    <div className="relative px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                        <div className="lg:col-start-2 lg:pl-8">
                            <div className="mx-auto my-36 max-w-prose text-base lg:ml-auto lg:mr-0 lg:max-w-lg">
                                <h3 className="mt-2 text-3xl font-semibold leading-loose tracking-tight text-gray-900 sm:text-4xl md:text-5xl">Not all custom canvas shops are created equal.</h3>
                                <p className="mt-8 text-lg text-gray-500">We built our company to focus exclusively on providing the best canvas prints and greatest options at an affordable price. We use North American red pine wood frames, premium quality 100% cotton canvas, and eco-friendly industry certified archival inks as well as we offer multiple frame depth and canvas edge options.</p>
                                <div className="prose prose-black mt-5 text-gray-500">
                                    <p className="mb-6">Every order is backed by our 20-year guarantee and by our dedicated support team.</p>
                                    <p className="mb-1">Questions? Call or email us!</p>
                                    <p className="mb-1"><a href="mailto:americancanvasprints@gmail.com">americancanvasprints@gmail.com</a></p>
                                    <p className="mb-1">Office: <a href="tel:(714) 770-0101">(714) 770-0101</a></p>
                                    <p>Fax: <a href="tel:(714) 770-0320">(714) 770-0320</a></p>
                                    <p className="mt-6">You can also come visit our shop located  in Fullerton, California!</p>
                                </div>
                                <div className="mt-4 flex">
                                    <Link to='/canvas/order' className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8">Order Custom Canvas</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover" src="assets/images/how-it-works.jpeg" loading="lazy" alt="" />
                        <div className="absolute inset-0 bg-black/60 mix-blend-multiply" aria-hidden="true"></div>
                    </div>

                    <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <div className="mt-6 mx-auto max-w-3xl text-xl text-center text-white space-y-10">
                            <h4 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-6xl">How it Works</h4>

                            <div>
                                <p>1. Upload Your File</p>
                                <p>We accept JPG, PNG, &amp; PDF file formats.</p>
                                <p>Please ensure your file has sufficient resolution for the size canvas you are ordering.</p>
                            </div>

                            <div>
                                <p>2. Choose Your Options</p>
                                <p>Select your: Canvas Size, Canvas Depth, Canvas Type and Edge Style and the finish.</p>
                                <p>Scroll down to learn a bit more about these options.</p>
                            </div>

                            <div>
                                <p>3. Place Your Order &amp; Approve Proof</p>
                                <p>After placing an order, our technicians will email you a proof for approval prior to start processing your order.</p>
                                <p>We then print, handcraft and ship your custom canvas directly to you.</p>
                            </div>

                            <div>
                                <p>(You're also welcome to pick up orders at our Fullerton, CA location)</p>
                            </div>

                            <div>
                                <Link to='/canvas/order' className="rounded-md border border-transparent bg-yellow-500 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8">Order Now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover" src="https://images.unsplash.com/photo-1549716679-95380658d5cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3870&q=80" loading="lazy" alt="" />
                        <div className="absolute inset-0 bg-black/40 mix-blend-multiply" aria-hidden="true"></div>
                    </div>

                    <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-48 sm:px-6 lg:px-8">
                        <div className="mt-6 mx-auto max-w-3xl text-xl text-center text-white space-y-10">
                            <h4 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-6xl">Our gallery with ready made canvas prints</h4>

                            <div className="space-y-3">
                                <p className="mb-6 font-semibold text-xl">Order now in time for Christmas 🎁</p>

                                <div>
                                  <Link
                                      to='/our-gallery'
                                      className="rounded-md border border-transparent bg-yellow-500 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                                  >
                                      View Our Gallery
                                  </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-orange-50">
                    <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 sm:py-24 lg:max-w-4xl lg:px-8 text-center">
                        <h2 className="text-4xl font-semibold text-gray-900 sm:text-5xl">
                            <em className="block">Our goal is to provide low prices on high quality products and excellent.</em>
                        </h2>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover" src="assets/images/frame-depth.jpeg" loading="lazy" alt="" />
                        <div className="absolute inset-0 bg-black/40 mix-blend-multiply" aria-hidden="true"></div>
                    </div>

                    <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <div className="mt-6 mx-auto max-w-3xl text-xl text-center text-white space-y-10">
                            <h4 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-6xl">Canvas Frame Depth</h4>

                            <div className="space-y-3">
                                <p className="font-semibold text-xl">Choose from two frame depths</p>
                                <p className="font-semibold text-xl">Gallery (1.5") or Standard (.75")</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover" src="assets/images/canvas-types.jpeg" loading="lazy" alt="" />
                        <div className="absolute inset-0 bg-black/40 mix-blend-multiply" aria-hidden="true"></div>
                    </div>

                    <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <div className="mt-6 mx-auto max-w-3xl text-xl text-center text-white space-y-10">
                            <h4 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-6xl">Canvas Type Options</h4>

                            <div className="md:mx-32">
                                <p className="font-semibold text-xl">We offer premium 100% cotton canvases, in addition to polyester and blended materials</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover" src="assets/images/edge-options.jpeg" loading="lazy" alt="" />
                        <div className="absolute inset-0 bg-black/40 mix-blend-multiply" aria-hidden="true"></div>
                    </div>

                    <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <div className="mt-6 mx-auto max-w-3xl text-xl text-center text-white space-y-10">
                            <h4 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-6xl">Edge Options</h4>

                            <div className="md:mx-32">
                                <p className="font-semibold text-xl">Choose from three edge options Image Wrap, Mirrored Image or Solid Border (sides in any color you prefer)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 text-center">
                        <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            <span className="block">Ready for your custom canvas?</span>
                        </h2>
                        <div className="mt-6 flex items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
                            <Link to='/canvas/order' className="flex items-center justify-center rounded-md border border-transparent px-4 py-3 text-lg font-medium bg-yellow-500 text-white shadow-sm">Order Today!</Link>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-gray-100" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">Footer</h2>
                <div className="mx-auto max-w-7xl px-4 pb-8 sm:px-6 lg:px-8">
                    <div className="pt-8 md:flex md:items-center md:justify-between">
                        <div className="flex items-center space-x-6 md:order-2">
                            <a href="https://facebook.com/profile.php?id=100086597869172" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Facebook</span>
                                <svg className="w-auto h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                                </svg>
                            </a>

                            <a href="https://www.instagram.com/americancanvasprints" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Instagram</span>
                                <svg className="w-auto h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                                </svg>
                            </a>

                            <a href="https://twitter.com/acpinccorp" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="w-auto h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                            </a>

                            <a href="https://www.pinterest.com/americancanvasprints" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Pinterest</span>
                                <svg className="w-auto h-5" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                                    <g>
                                        <path d="M0,128.002 C0,180.416 31.518,225.444 76.619,245.241 C76.259,236.303 76.555,225.573 78.847,215.848 C81.308,205.457 95.317,146.1 95.317,146.1 C95.317,146.1 91.228,137.927 91.228,125.848 C91.228,106.879 102.222,92.712 115.914,92.712 C127.557,92.712 133.182,101.457 133.182,111.929 C133.182,123.633 125.717,141.14 121.878,157.355 C118.671,170.933 128.686,182.008 142.081,182.008 C166.333,182.008 182.667,150.859 182.667,113.953 C182.667,85.899 163.772,64.901 129.405,64.901 C90.577,64.901 66.388,93.857 66.388,126.201 C66.388,137.353 69.676,145.217 74.826,151.307 C77.194,154.104 77.523,155.229 76.666,158.441 C76.052,160.796 74.642,166.466 74.058,168.713 C73.206,171.955 70.579,173.114 67.649,171.917 C49.765,164.616 41.436,145.031 41.436,123.015 C41.436,86.654 72.102,43.054 132.918,43.054 C181.788,43.054 213.953,78.418 213.953,116.379 C213.953,166.592 186.037,204.105 144.887,204.105 C131.068,204.105 118.069,196.635 113.616,188.15 C113.616,188.15 106.185,217.642 104.611,223.337 C101.897,233.206 96.585,243.07 91.728,250.758 C103.24,254.156 115.401,256.007 128.005,256.007 C198.689,256.007 256.001,198.698 256.001,128.002 C256.001,57.309 198.689,0 128.005,0 C57.314,0 0,57.309 0,128.002 Z" fill="currentColor"></path>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <p className="mt-8 text-sm text-gray-600 md:order-1 md:mt-0">Copyright &copy; {(new Date().getFullYear())} American Canvas Prints. All Rights Reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
}
