import { baseApi } from "../../../api/base-api";
import { SinglePrintBorder } from "../../canvas/models/single-print-border.model";
import { UpdateSinglePrintBorderDto } from "./dto";

async function getSinglePrintBorders() {
    return await baseApi.get<SinglePrintBorder[]>('canvas/single-print-borders');
}

async function getSinglePrintBorderById(borderId: number) {
    return await baseApi.get<SinglePrintBorder>(`canvas/single-print-borders/${borderId}`);
}

async function updateSinglePrintBorder(borderId: number, updateData: UpdateSinglePrintBorderDto) {
    return await baseApi.put(`canvas/single-print-borders/${borderId}`, updateData);
}

export const singlePrintBordersApi = {
    getSinglePrintBorders: getSinglePrintBorders,
    getSinglePrintBorderById: getSinglePrintBorderById,
    updateSinglePrintBorder: updateSinglePrintBorder,
} as const;
