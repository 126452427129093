export class OtherPrice {
    id!: number;
    type!: OtherPriceType;
    price!: number;
    wholesalePrice!: number;
}

export enum OtherPriceType {
    LAMINATE_PRICE_PER_SQFT = "LAMINATE_PRICE_PER_SQFT",
    PACKAGING_PRICE_PER_SQFT = "PACKAGING_PRICE_PER_SQFT",
    MIN_PACKAGING_PRICE = "MIN_PACKAGING_PRICE",
}
